
<h1 mat-dialog-title class="text-danger">Alert </h1>
<div mat-dialog-content class="title">
  <p>{{data.viewData}}</p>
 
</div>
<div mat-dialog-actions class="buttons">
    <button class="btn-yes" mat-button 
    mat-dialog-close ="yes" cdkFocusInitial>Yes</button>
    
    <button class="btn-cancel" mat-button (click)="onNoClick()">No</button>
</div>

