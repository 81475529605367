import { Injectable } from "@angular/core";
import {API_CURRENT_VERSION, EndPointBaseUrl} from "../../../common/settings/settings";
import {CommunicatorService} from "../../../common/services/communicator.service";
import {BehaviorSubject} from "rxjs";
import {IClient} from "../model/client";
import {NotificationService} from "../../../common/services/notification.service";
import {ErrorMessageService} from "../../../common/services/error-message.service";
import {HttpParams} from "@angular/common/http";
import {SelectProjectService} from "../../../project/management/select-project/select-project.service";

@Injectable({
  providedIn: "root"
})
export class SelectClientService {
  public selectedClientData = new BehaviorSubject<any>([IClient]);
  public selectedClientData$ = this.selectedClientData.asObservable();
  public providerList = {};
  public providerDetails = new BehaviorSubject<any>([]);
  providerDetails$ = this.providerDetails.asObservable();
  public providerFullName: string;

  constructor(private communicatorServiceObject: CommunicatorService,
              public notificationService: NotificationService,
              public errorMessageService: ErrorMessageService, private selectedProjectService: SelectProjectService) { }

  getClientData(clientId) {
    this.communicatorServiceObject.getData(EndPointBaseUrl + API_CURRENT_VERSION +
      "/clients/" + clientId).subscribe(data => {
        if (data !== null && data !== undefined) {
          const clients = data;
          this.selectedClientData.next(clients);
          this.subscribeToAllProjectConfigOfSelectedClient(clientId);
        }
      }, error => {
        const manipulatedErrorMessage = this.errorMessageService.manipulateErrorMessageForDisplayingOnSnackBar(error);
        if (manipulatedErrorMessage !== undefined && manipulatedErrorMessage !== null && manipulatedErrorMessage !== '') {
          this.notificationService.showError(manipulatedErrorMessage);
        }
      }
    );
  }

  subscribeToAllProjectConfigOfSelectedClient(clientId) {
    this.selectedProjectService.getAllProjectConfigOfSelectedClient(clientId);
  }

  subscribeToProviderListChanges(clientId) {
    if (clientId in this.providerList) {
      this.providerDetails.next(this.providerList[clientId]);
    } else {
      this.getProviderList(clientId);
    }
  }

  /**
   * get list of providers.
   */
  public getProviderList(clientId) {
    let params = new HttpParams();
    if (clientId !== undefined && clientId !== null) {
      params = params.append('clientId', clientId);
    }
    params = params.append('status', 1);
    params = params.append('ordering', "preference,first_name,facility_name,group_name");
    this.communicatorServiceObject.getData(EndPointBaseUrl + API_CURRENT_VERSION + "/providers",  params).subscribe(ProviderData => {
      const providers = ProviderData.results;
      for (let i = 0; i < providers.length; i++) {
        if (providers[i] !== null) {
          providers[i].firstName = providers[i]['firstName'];
          providers[i].lastName = providers[i]['lastName'];
          providers[i].groupName = providers[i]['groupName'];
          providers[i].facilityName = providers[i]['facilityName'];
          providers[i].npi = providers[i]['npi'];
          providers[i].type = providers[i]['type'];
          providers[i].text = this.getFullName(providers[i]);
          providers[i].value = this.getFullName(providers[i].id);
        }
      }
      this.providerList[clientId] = providers;
      this.providerDetails.next(this.providerList[clientId]);
    }, (error) => {
      if (error.statusCode !== undefined && error.statusCode !== null && error.statusCode === 500) {
        this.notificationService.showError("Failed to process your request");
      } else {
        const manipulatedErrorMessage = this.errorMessageService.manipulateErrorMessageForDisplayingOnSnackBarWithoutKey(error);
        if (manipulatedErrorMessage !== undefined && manipulatedErrorMessage !== null && manipulatedErrorMessage !== '') {
          this.notificationService.showError(manipulatedErrorMessage);
        }
      }
    });
  }

  /**
   * type
   * 1 -> individual
   * 2 -> group name
   * 3 -> facility name
   * @param providerListArray
   */
  getFullName(providerListArray) {
    this.providerFullName = "";
    if (providerListArray.type === 1) {
      if (providerListArray.lastName !== null && providerListArray.lastName !== undefined && providerListArray.lastName !== "") {
        this.providerFullName = this.providerFullName.concat((providerListArray.lastName).toString()) + "," + " ";
      }
      if (providerListArray.firstName !== null && providerListArray.firstName !== undefined && providerListArray.firstName !== "") {
        this.providerFullName = this.providerFullName.concat((providerListArray.firstName).toString()) + " " + "-" + " ";
      }
      if (providerListArray.npi !== null && providerListArray.npi !== undefined && providerListArray.npi !== "") {
        this.providerFullName = this.providerFullName.concat((providerListArray.npi).toString());
      }
    }
    if (providerListArray.type === 2) {
      if (providerListArray.groupName !== null && providerListArray.groupName !== undefined && providerListArray.groupName !== "") {
        this.providerFullName = providerListArray.groupName + " " + "-" + " ";
      }
      if (providerListArray.npi !== null && providerListArray.npi !== undefined && providerListArray.npi !== "") {
        this.providerFullName = this.providerFullName.concat((providerListArray.npi).toString());
      }
    }
    if (providerListArray.type === 3) {
      if (providerListArray.facilityName !== null && providerListArray.facilityName !== undefined && providerListArray.facilityName !== "") {
        this.providerFullName = providerListArray.facilityName + " " + "-" + " ";
      }
      if (providerListArray.npi !== null && providerListArray.npi !== undefined && providerListArray.npi !== "") {
        this.providerFullName = this.providerFullName.concat((providerListArray.npi).toString());
      }
    }
    return this.providerFullName;
  }
}
