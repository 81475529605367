import { Component, Inject, OnDestroy, OnInit, Optional } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Subscription } from "rxjs";
import { CommunicatorService } from "../common/services/communicator.service";
import { AuthService } from "../common/services/auth.service";
import { API_CURRENT_VERSION, EndPointBaseUrl, SessionAutoLogOutTime } from "../common/settings/settings";
import { ErrorMessageService } from "../common/services/error-message.service";
import { NotificationService } from "../common/services/notification.service";
import { Idle } from "@ng-idle/core";
import { DataService } from "../common/services/data.service";

@Component({
  selector: "app-session-expired",
  templateUrl: "./session-expired.component.html",
  styleUrls: ["./session-expired.component.scss"]
})
export class SessionExpiredComponent implements OnInit, OnDestroy {
  private sessionTimeOut: any;

  constructor(@Optional() @Inject(MAT_DIALOG_DATA) public data, public dialog: MatDialog, private fb: FormBuilder, private router: Router,
    private communicatorServiceObject: CommunicatorService,
    private AuthServiceObject: AuthService, public dialogRef: MatDialogRef<SessionExpiredComponent>,
    private errorMessageService: ErrorMessageService, private notificationService: NotificationService,
    private idle: Idle,
    private dataService: DataService) {
  }

  ngOnDestroy() { }

  ngOnInit() {
    this.sessionTimeOut = setTimeout(() => {
      this.dataService.shareAction("logOut", null);
    }, SessionAutoLogOutTime);
  }

  onClickingYesButton() {
    this.communicatorServiceObject.postData(EndPointBaseUrl + API_CURRENT_VERSION + "/session/extend", null)
      .subscribe((responseData) => {
        if (this.sessionTimeOut !== null) {
          clearTimeout(this.sessionTimeOut);
        }
        this.idle.watch();
        this.dialogRef.close();
      }, error => {
        const manipulatedErrorMessage = this.errorMessageService.manipulateErrorMessageForDisplayingOnSnackBar(error);
        if (manipulatedErrorMessage !== undefined && manipulatedErrorMessage !== null && manipulatedErrorMessage !== '') {
          this.notificationService.showError(manipulatedErrorMessage);
        }
      });
  }

}
