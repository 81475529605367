import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-system-offline',
  templateUrl: './system-offline.component.html',
  styleUrls: ['./system-offline.component.scss']
})
export class SystemOfflineComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
