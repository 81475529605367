import { Component, Inject, OnInit, Optional } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-location-type-change-dialog',
  templateUrl: './location-type-change-dialog.component.html',
  styleUrls: ['./location-type-change-dialog.component.scss']
})
export class LocationTypeChangeDialogComponent implements OnInit {

  public messageToBeDisplayed: string;
  public isConfirmationPopup: boolean;
  public actionPerformed: string;

  constructor(@Optional() @Inject(MAT_DIALOG_DATA) public data,
  private dialogRef: MatDialogRef<LocationTypeChangeDialogComponent>) { }

  ngOnInit(): void {
    if (this.data !== undefined && this.data !== null) {
      if (this.data['messages'] !== undefined && this.data['messages'] !== null) {
        this.messageToBeDisplayed = this.data['messages'];
      }
    }
  }

  closePopUp() {
    this.dialogRef.close();
  }

}
