import {Injectable} from "@angular/core";
import {
  API_CURRENT_VERSION,
  CompleteAccessResource,
  EndPointBaseUrl, ExclusionQueuesResource, MasterQueueResource,
  MiscResource,
  PartialAccessResource
} from "../common/settings/settings";
import {BehaviorSubject, Subject} from "rxjs";
import {IClient} from "../client/management/model/client";
import {CommunicatorService} from "../common/services/communicator.service";
import {NotificationService} from "../common/services/notification.service";
import {ErrorMessageService} from "../common/services/error-message.service";

@Injectable({
  providedIn: "root"
})
export class UserService {
  public loggedInUserPermissions = new BehaviorSubject<any>(null);
  public loggedInUserPermissions$ = this.loggedInUserPermissions.asObservable();
  public activeInactiveConfirmation = new Subject<any>();
  public activeInactiveConfirmation$ = this.activeInactiveConfirmation.asObservable();
  public userDetails;
  public permissionList = [];
  public clientPermissionList = [];
  public listOfUniquePermissions = [];
  public listOfUniqueClientPermissions = [];
  public userFullName: string;
  public adminPermissionRoles;
  public adminPermissionRolesList = new Subject<any>();
  public adminPermissionRolesList$ = this.adminPermissionRolesList.asObservable();
  public projectFteStatus;
  public projectFteStatusList = new Subject<any>();
  public projectFteStatusList$ = this.projectFteStatusList.asObservable();
  public selectedUserData;
  public selectedUserListDetails = new Subject<any>();
  public selectedUserListDetails$ = this.selectedUserListDetails.asObservable();
  public access: boolean;

  constructor(private communicatorServiceObject: CommunicatorService,
              public notificationService: NotificationService,
              public errorMessageService: ErrorMessageService) {
  }

  getLoggedInPermissions() {
    if (this.userDetails !== undefined && this.userDetails !== null) {
      this.loggedInUserPermissions.next(this.userDetails);
    } else {
      this.communicatorServiceObject.getData(EndPointBaseUrl + API_CURRENT_VERSION +
        "/users/permissions").subscribe(data => {
          if (data !== undefined && data !== null) {
            this.userDetails = data;
            this.getProjectPermissions(this.userDetails);
            this.loggedInUserPermissions.next(this.userDetails);
          }
        }, error => {
          if (error.statusCode !== undefined && error.statusCode !== null && error.statusCode === 500) {
            this.notificationService.showError("Failed to process your request");
          } else {
            const manipulatedErrorMessage = this.errorMessageService.manipulateErrorMessageForDisplayingOnSnackBarWithoutKey(error);
            if (manipulatedErrorMessage !== undefined && manipulatedErrorMessage !== null && manipulatedErrorMessage !== '') {
              this.notificationService.showError(manipulatedErrorMessage);
            }
          }
        }
      );
    }
  }

  convertListData(data) {
    if (data !== undefined && data !== null && data.length > 0) {
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < data.length; i++) {
        data[i].name = this.getFullName(data[i]);
      }
    }
  }

  getFullName(userArray) {
    this.userFullName = "";
    if (userArray.firstName !== undefined && userArray.firstName !== null && userArray.firstName !== "") {
      this.userFullName = this.userFullName.concat((userArray.firstName).toString()) + " ";
    }
    if (userArray.lastName !== undefined && userArray.lastName !== null && userArray.lastName !== "") {
      this.userFullName = this.userFullName.concat((userArray.lastName).toString());
    }
    return this.userFullName;
  }

  getActiveInactiveConfirmation(confirmation, data) {
    const userObj = {isConfirmed: confirmation, userData: data};
    this.activeInactiveConfirmation.next(userObj);
  }

  getProjectPermissions(userData) {
    if (userData !== undefined && userData !== null) {
      // tslint:disable-next-line:forin
      for (const client in userData.clients) {
        // tslint:disable-next-line:forin
        this.clientPermissionList = [];
        this.permissionList = [];
        for (const project in userData.clients[client].projects) {
          // tslint:disable-next-line:forin
          for (const permission in userData.clients[client].projects[project].permissions) {
            this.permissionList.push({
              name: userData.clients[client].projects[project].permissions[permission].name,
              id: userData.clients[client].projects[project].permissions[permission].id
            });
            // tslint:disable-next-line:forin
            for (const subPermission in
              userData.clients[client].projects[project].permissions[permission].subResources) {
              this.permissionList.push({
                name: userData.clients[client].projects[project].permissions[permission].subResources[subPermission].name,
                id: userData.clients[client].projects[project].permissions[permission].subResources[subPermission].id
              });
            }
          }
          userData.clients[client].projectUniquePermission = this.getUniqueProjectPermissions(this.permissionList);
        }

        for (const clientPermission in userData.clients[client].permissions) {
          this.clientPermissionList.push({
            name: userData.clients[client].permissions[clientPermission].name,
            id: userData.clients[client].permissions[clientPermission].id
          });
          for (const subPermission in
            userData.clients[client].permissions[clientPermission].subResources) {
            this.clientPermissionList.push({
              name: userData.clients[client].permissions[clientPermission].subResources[subPermission].name,
              id: userData.clients[client].permissions[clientPermission].subResources[subPermission].id
            });
          }
          userData.clients[client].clientUniquePermission = this.getUniqueClientPermissions(this.clientPermissionList);
        }
      }
    }
  }

  getUniqueProjectPermissions(permissions) {
    this.listOfUniquePermissions = [];
    const uploadedByArray = permissions.map((item) => {
      return item;
    });
    uploadedByArray.forEach((item) => {
      const i = this.listOfUniquePermissions.findIndex(x => x.id === item.id);
      if (i <= -1) {
        this.listOfUniquePermissions.push({name: item.name, id: item.id});
      }
    });
    return this.listOfUniquePermissions;
  }

  getUniqueClientPermissions(permissions) {
    this.listOfUniqueClientPermissions = [];
    const uploadedByArray = permissions.map((item) => {
      return item;
    });
    uploadedByArray.forEach((item) => {
      const i = this.listOfUniqueClientPermissions.findIndex(x => x.id === item.id);
      if (i <= -1) {
        this.listOfUniqueClientPermissions.push({name: item.name, id: item.id});
      }
    });
    return this.listOfUniqueClientPermissions;
  }

  getFullUserName(userDetails) {
    this.userFullName = "";
    if (userDetails !== undefined && userDetails !== null && userDetails.firstName !== null &&
      userDetails.firstName !== undefined && userDetails.firstName !== "") {
      this.userFullName = this.userFullName.concat((userDetails.firstName).toString()) + " ";
    }
    if (userDetails !== undefined && userDetails !== null && userDetails.lastName !== null &&
      userDetails.lastName !== undefined && userDetails.lastName !== "") {
      this.userFullName = this.userFullName.concat((userDetails.lastName).toString());
    }
    return this.userFullName;
  }

  getProjectRolesOfSelectedProject(projectId) {
    const permissions = [];
    const roles = [];
    if (this.userDetails !== undefined && this.userDetails !== null) {
      if (this.userDetails.clients !== undefined && this.userDetails.clients !== null) {
        for (const projectItem of projectId) {
          for (const client of this.userDetails.clients) {
            for (const project of client.projects) {
              if (project.id === projectItem) {
                for (const permission of project.permissions) {
                  if (permission.id === MasterQueueResource) {
                    for (const subPermission of permission.subResources) {
                      if (subPermission.id === ExclusionQueuesResource) {
                        for (const childResources of subPermission.subResources) {
                          permissions.push(childResources.id);
                          roles.push(childResources.id);
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    const uniqueProjectPermissions = [...new Set(permissions)];
    return uniqueProjectPermissions;
  }

  getSelectedProjectPermission(projectId) {
    if (this.userDetails !== undefined && this.userDetails !== null) {
      let access = false;
      for (const client in this.userDetails.clients) {
        // tslint:disable-next-line:forin
        for (const project in this.userDetails.clients[client].projects) {
          if (projectId === this.userDetails.clients[client].projects[project].id) {
            const projectPermission = this.userDetails.clients[client].projects[project].permissions;
            for (const permission in projectPermission) {
              if (projectPermission[permission].id === MiscResource) {
                for (const subPermission in projectPermission[permission].subResources) {
                  for (const subResourcesPermission in projectPermission[permission].subResources[subPermission].subResources) {
                    const subResource = projectPermission[permission].subResources[subPermission].subResources[subResourcesPermission].id;
                    if (subResource === PartialAccessResource) {
                      access = false;
                    }
                    if (subResource === CompleteAccessResource) {
                      access = true;
                    }
                  }
                }
              }
            }
          }
        }
      }
      return access;
    }
  }

  getAdminPermissionList(id, ordering, create) {
    if (this.adminPermissionRoles !== undefined && this.adminPermissionRoles !== null && this.adminPermissionRoles.length > 0) {
      this.adminPermissionRolesList.next(this.adminPermissionRoles);
    } else {
      let queryParams = {};
      if (id !== undefined && id !== null) {
        queryParams['id'] = id;
      }
      if (ordering !== undefined && ordering !== null) {
        queryParams['ordering'] = ordering;
      }
      if (create !== undefined && create !== null) {
        queryParams['create'] = create;
      }
      queryParams['status'] = 1;
      queryParams = queryParams || {};
      let queryString = Object.keys(queryParams).map(key => encodeURIComponent(key) + "=" + encodeURIComponent(queryParams[key])).join("&");
      if (queryString !== "") {
        queryString = "?" + queryString;
      }
      this.communicatorServiceObject.getData(EndPointBaseUrl + API_CURRENT_VERSION +
        "/common/resources" + queryString).subscribe(data => {
          if (data !== undefined && data !== null) {
            this.adminPermissionRoles = data.results;
            this.getAdminPermissionArray(this.adminPermissionRoles);
            this.adminPermissionRolesList.next(this.adminPermissionRoles);
          }
        }, error => {
          if (error.statusCode !== undefined && error.statusCode !== null && error.statusCode === 500) {
            this.notificationService.showError("Failed to process your request");
          } else {
            const manipulatedErrorMessage = this.errorMessageService.manipulateErrorMessageForDisplayingOnSnackBarWithoutKey(error);
            if (manipulatedErrorMessage !== undefined && manipulatedErrorMessage !== null && manipulatedErrorMessage !== '') {
              this.notificationService.showError(manipulatedErrorMessage);
            }
          }
        }
      );
    }
  }

  getAdminPermissionArray(adminPermissionRoles) {
    if (adminPermissionRoles !== undefined && adminPermissionRoles !== null) {
      const permissions = [];
      for (const item in adminPermissionRoles) {
        if (adminPermissionRoles[item].subResources !== undefined && adminPermissionRoles[item].subResources !== null &&
          adminPermissionRoles[item].subResources.length > 0) {
          for (const subPermission in adminPermissionRoles[item].subResources) {
            permissions.push(adminPermissionRoles[item].subResources[subPermission]);
          }
        } else {
          permissions.push(adminPermissionRoles[item]);
        }
      }
      adminPermissionRoles['permissions'] = permissions;
    }
  }

  getFteStatusList() {
    if (this.projectFteStatus !== undefined && this.projectFteStatus !== null && this.projectFteStatus.length > 0) {
      this.projectFteStatusList.next(this.projectFteStatus);
    } else {
      this.communicatorServiceObject.getData(EndPointBaseUrl + API_CURRENT_VERSION +
        "/common/fte-status").subscribe(data => {
          if (data !== undefined && data !== null) {
            this.projectFteStatus = data.results;
            this.projectFteStatusList.next(this.projectFteStatus);
          }
        }, error => {
          if (error.statusCode !== undefined && error.statusCode !== null && error.statusCode === 500) {
            this.notificationService.showError("Failed to process your request");
          } else {
            const manipulatedErrorMessage = this.errorMessageService.manipulateErrorMessageForDisplayingOnSnackBarWithoutKey(error);
            if (manipulatedErrorMessage !== undefined && manipulatedErrorMessage !== null && manipulatedErrorMessage !== '') {
              this.notificationService.showError(manipulatedErrorMessage);
            }
          }
        }
      );
    }
  }

  getUserData(userId) {
    if (userId !== undefined && userId !== null) {
      this.communicatorServiceObject.getData(EndPointBaseUrl + API_CURRENT_VERSION +
        "/users/" + userId).subscribe(data => {
          if (data !== undefined && data !== null) {
            this.selectedUserData = data;
            this.convertData(this.selectedUserData);
            this.selectedUserListDetails.next(this.selectedUserData);
          }
        }, error => {
          if (error.statusCode !== undefined && error.statusCode !== null && error.statusCode === 500) {
            this.notificationService.showError("Failed to process your request");
          } else {
            const manipulatedErrorMessage = this.errorMessageService.manipulateErrorMessageForDisplayingOnSnackBarWithoutKey(error);
            if (manipulatedErrorMessage !== undefined && manipulatedErrorMessage !== null && manipulatedErrorMessage !== '') {
              this.notificationService.showError(manipulatedErrorMessage);
            }
          }
        }
      );
    }
  }

  convertData(data) {
    if (data !== undefined && data !== null) {
      data.supervisorList = [];
      // tslint:disable-next-line:prefer-for-of forin
      for (const dataItem in data.supervisor) {
        if (data.supervisor[dataItem] !== undefined && data.supervisor[dataItem] !== null) {
          data.supervisorList.push(data.supervisor[dataItem].id);
        }
      }
    }
  }

  getChartRepositoryPermissions(projectId) {
    if (this.userDetails !== undefined && this.userDetails !== null) {
      for (const clientIndex of this.userDetails.clients) {
        for (const projectIndex of clientIndex.projects) {
          for (const id of projectId) {
            if (id === projectIndex.id) {
              for (const permissions of projectIndex.permissions) {
                if (permissions.id === MiscResource) {
                  for (const subPermissions of permissions.subResources) {
                    for (const subResources of subPermissions.subResources) {
                      if (subResources.id === PartialAccessResource) {
                        this.access = false;
                        break;
                      }
                      if (subResources.id === CompleteAccessResource) {
                        this.access = true;
                        break;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      return this.access;
    }
  }

  updatedUserProfile(userData) {
    if (userData !== undefined && userData !== null) {
      if (this.userDetails !== undefined && this.userDetails !== null) {
        if (this.userDetails.id === userData.id) {
          this.userDetails = userData;
          this.loggedInUserPermissions.next(this.userDetails);
        }
      }
    }
  }
}
