import {Component, OnInit, OnDestroy, ViewChild} from "@angular/core";
import {MatDialogRef} from "@angular/material/dialog";
import {EducationDialogService} from "./education-dialog.service";
import {Subscription} from "rxjs";
import {TextWrapSettingsModel} from "@syncfusion/ej2-grids";
import {SelectClientService} from "../../client/management/select-client/select-client.service";
import { IEducation } from "../management/model/education-model";
import {MaxLimitForEducationsDocument} from "../../common/settings/settings";
import {Router} from "@angular/router";
import {GridComponent} from "@syncfusion/ej2-angular-grids";

@Component({
  selector: "app-education-dialog",
  templateUrl: "./education-dialog.component.html",
  styleUrls: ["./education-dialog.component.scss"]
})
export class EducationDialogComponent implements OnInit, OnDestroy {
  public educationList: IEducation[];
  public list$: Subscription;
  public dateFormat: { type: string, format: string };
  public wrapSettings: TextWrapSettingsModel;
  public userId: number;
  public clientId: number;
  @ViewChild("educationGrid")
  public educationGrid: GridComponent;

  constructor(private dialogRef: MatDialogRef<EducationDialogComponent>,
              public educationDialogService: EducationDialogService,
              public selectClientService: SelectClientService, private router: Router) { }

  ngOnInit() {
    this.checkPreCondition();
    this.wrapSettings = { wrapMode: "Header" };
    this.dateFormat = {type: "date", format: "MM/dd/yyyy"};
  }

  checkPreCondition() {
    const userId = sessionStorage.getItem("userId");
    const clientId = sessionStorage.getItem("activeClient");
    if (userId === undefined || userId === null) {
      this.userId = Number(userId);
      this.router.navigate(["/session/signin"]);
    } else if (clientId === undefined || clientId === null) {
      this.router.navigate(["/client"]);
    } else {
      this.clientId = Number(clientId);
      if (this.clientId !== undefined && this.clientId !== null) {
        this.getEducationList(this.clientId, "-date_uploaded");
      }
    }
  }

  getEducationList(clientId, ordering) {
    this.educationDialogService.getPublishedEducationList(clientId, true, ordering);
    this.list$ = this.educationDialogService.educationList$.subscribe(data => {
      if (data !== undefined && data !== null && data.length > 0) {
        this.educationList = data;
      } else {
        this.educationList = [];
        (this.educationGrid.localeObj as any).localeStrings.EmptyRecord = "No Records To Display";
      }
    });
  }

  closeEducationPopup() {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    if (this.list$) {
      this.list$.unsubscribe();
    }
  }

  openDocument(documentURL) {
    window.open(documentURL, "guideline-document-" + this.educationDialogService.educationsDialogIndex % MaxLimitForEducationsDocument,
      "scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,\n" +
      "width=0,height=0,left=-1000,top=-1000");
    this.educationDialogService.educationsDialogIndex = this.educationDialogService.educationsDialogIndex + 1;
  }

  onLoadEducationGrid() {
    (this.educationGrid.localeObj as any).localeStrings.EmptyRecord = "Fetching Data...";
  }

}
