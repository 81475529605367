import {Component, OnInit, ViewEncapsulation} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-layout",
  template: `
    <app-layout-inner></app-layout-inner>
  `,
  encapsulation: ViewEncapsulation.None
})
export class AdminLayoutComponent {}

@Component({
  selector: "app-layout-inner",
  templateUrl: "./admin-layout.component.html"
})
export class LayoutComponent implements OnInit {
  public showHeader: boolean = true;
  constructor(private route:Router, private activatedRoute: ActivatedRoute) {
  }

  ngOnInit() {
    if(this.route.url.indexOf('/pdfview') != -1){
      this.activatedRoute.queryParams.subscribe(params => {
        if (params !== undefined && params !== null) {
          if (params['showHeader'] !== undefined && params['showHeader'] !== null && params['showHeader'] !== "") {
            this.showHeader =JSON.parse(params['showHeader']);
          }else{
            this.showHeader = true;
          }    
        }
      });
    }
  }
}
