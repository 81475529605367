import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class ConfigurationService {
  snackBarTiming = 8000;
  requestInEverySecond = 60000;
  maxFileSize = 245000000;

  constructor() { }
}
