<div cdkDrag cdkDragRootElement=".cdk-overlay-pane"
     cdkDragBoundary=".cdk-overlay-container" cdkDragHandle class="dialog-drag">
  <div fxLayout="row wrap">
    <div fxLayout="row wrap" fxLayoutAlign="center">
      <h3>External User</h3>
    </div>
    <div fxFlex></div>
    <button mat-icon-button matTooltip="Click here to close the popup"
            (click)="closePopUp($event)">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
<div fxLayout="row wrap">
  <span>
    <p>
      User can be associated with only one client to be marked as External.
      Please edit to associated to only one client with the user.
    </p>
  </span>
</div>
<div fxLayout="row" fxLayoutGap="3px" fxLayoutAlign="end">
  <button data-testid="Yes" mat-raised-button color="primary" cdkFocusInitial type="submit"
          (click)="onConfirm($event)">
    Ok
  </button>
</div>
