import { Injectable } from "@angular/core";
import {BehaviorSubject, Subject} from "rxjs";
import {CommunicatorService} from "../common/services/communicator.service";
import {API_CURRENT_VERSION, EndPointBaseUrl} from "../common/settings/settings";
import {NotificationService} from "../common/services/notification.service";
import {ErrorMessageService} from "../common/services/error-message.service";

@Injectable({
  providedIn: "root"
})
export class ReportsService {
  public reportsList = new BehaviorSubject<any>([]);
  public reportsList$ = this.reportsList.asObservable();
  public reportData;
  public reportDataForPowerBiIntegration = new Subject<any>();
  public reportDataForPowerBiIntegration$ = this.reportDataForPowerBiIntegration.asObservable();
  public dashboardData;
  public dashboardDataForPowerBiIntegration = new Subject<any>();
  public dashboardDataForPowerBiIntegration$ = this.dashboardDataForPowerBiIntegration.asObservable();
  public userProductivityDataForPowerBiIntegration = new Subject<any>();
  public userProductivityDataForPowerBiIntegration$ = this.userProductivityDataForPowerBiIntegration.asObservable();

  constructor(private communicatorServiceObject: CommunicatorService,
              public notificationService: NotificationService,
              public errorMessageService: ErrorMessageService) { }

  getReportDataThroughPowerBiIntegration(resourceId, client, project, report) {
    let queryParams = {};
    if (resourceId !== null && resourceId !== undefined) {
      queryParams['resourceId'] = resourceId;
    }
    queryParams = queryParams || {};
    let queryString = Object.keys(queryParams).map(key => encodeURIComponent(key) + "=" + encodeURIComponent(queryParams[key])).join("&");
    if (queryString !== "") {
      queryString = "?" + queryString;
    }
    let reportData = {};
    reportData['client'] = client;
    reportData['project'] = project;
    reportData['report'] = report;
    this.communicatorServiceObject.postData(EndPointBaseUrl + API_CURRENT_VERSION +
      "/power_bi_integration/static-id" + queryString, JSON.stringify(reportData)).subscribe(data => {
        if (data !== undefined && data !== null) {
          this.reportData = data;
          this.reportDataForPowerBiIntegration.next(this.reportData);
        }
      }, error => {
        const manipulatedErrorMessage = this.errorMessageService.manipulateErrorMessageForDisplayingOnSnackBar(error);
        this.notificationService.showError(manipulatedErrorMessage);
      }
    );
  }

  getUserProductivityThroughPowerBiIntegration(resourceId, client, report) {
    let queryParams = {};
    if (resourceId !== null && resourceId !== undefined) {
      queryParams['resourceId'] = resourceId;
    }
    queryParams = queryParams || {};
    let queryString = Object.keys(queryParams).map(key => encodeURIComponent(key) + "=" + encodeURIComponent(queryParams[key])).join("&");
    if (queryString !== "") {
      queryString = "?" + queryString;
    }
    let reportData = {};
    reportData['client'] = client;
    reportData['report'] = report;
    this.communicatorServiceObject.postData(EndPointBaseUrl + API_CURRENT_VERSION +
      "/power_bi_integration/static-id" + queryString, JSON.stringify(reportData)).subscribe(data => {
        if (data !== undefined && data !== null) {
          this.reportData = data;
          this.userProductivityDataForPowerBiIntegration.next(this.reportData);
        }
      }, error => {
        const manipulatedErrorMessage = this.errorMessageService.manipulateErrorMessageForDisplayingOnSnackBar(error);
        this.notificationService.showError(manipulatedErrorMessage);
      }
    );
  }

  getDashboardDataThroughPowerBiIntegration(resourceId, client, project, report) {
    let queryParams = {};
    if (resourceId !== undefined && resourceId !== null) {
      queryParams['resourceId'] = resourceId;
    }
    queryParams = queryParams || {};
    let queryString = Object.keys(queryParams).map(key => encodeURIComponent(key) + "=" + encodeURIComponent(queryParams[key])).join("&");
    if (queryString !== "") {
      queryString = "?" + queryString;
    }
    let reportData = {};
    reportData['client'] = client;
    reportData['project'] = project;
    reportData['report'] = report;
    this.communicatorServiceObject.postData(EndPointBaseUrl + API_CURRENT_VERSION +
      "/power_bi_integration/static-id" + queryString, JSON.stringify(reportData)).subscribe(data => {
        if (data !== undefined && data !== null) {
          this.dashboardData = data;
          this.dashboardDataForPowerBiIntegration.next(this.dashboardData);
9        }
      }, error => {
        const manipulatedErrorMessage = this.errorMessageService.manipulateErrorMessageForDisplayingOnSnackBar(error);
        this.notificationService.showError(manipulatedErrorMessage);
      }
    );
  }
}
