<div class="body" >

    <header>
        <ul class="nav-list">
            <li class="nav-item">
                <div class="box">
                    
                </div>
            </li>
            <li class="nav-item"><span><a (click)="closeChart()"><svg xmlns="http://www.w3.org/2000/svg" height="24px"
                viewBox="0 0 24 24" width="24px" fill="#000000">
                <path d="M0 0h24v24H0z" fill="none" />
                <path
                    d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
            </svg></a></span></li>
            
            
        </ul>
    </header>


    <nav>
        <ul class="row">
            <li class="col">
                <div class="photo-wrap">
                    <div class="photo"><img src="../../../../assets/images/avathar-placeholder.png" alt="profile picture" height="80px"
                            weight="40px"></div>
                    <div class="user-name">
                        <ul>
                            <li>Allergies: Allergies Not Recorded</li>
                            <li>Adv Dir Type:</li>
                            <li>Advance Directive:No,No</li>
                        </ul>
                    </div>
                </div>
            </li>
            <li class="col">
                <ul>
                    <li>Age:66 years</li>
                    <li>SexFemale</li>
                    <li>No xDocs</li>
                </ul>
            </li>
            <li class="col">
                <ul>
                    <li>Height:</li>
                    <li>Weight:97.7 kg</li>
                    <li>BMI:</li>
                </ul>
            </li>
            <li class="col">
                <ul>
                    <li>MRN:113584</li>
                    <li>Fin#,60016636</li>
                    <li>Contact</li>
                </ul>
            </li>
            <li class="col">
                <ul>
                    <li>DOB:4/27/1956</li>
                    <li>Loch</li>
                    <li>COVID-19: Not Ordered</li>
                </ul>
            </li>
            <li class="col">
                <ul>
                    <li>
                        <p>clinic FIN: 60016638 [visit Dt: 5/3/2022 10:48:00 EDT] Visit Reason:<_< /p>
                    </li>
                    <div class="split">
                        <ul>
                            <li>r;Exam 3</li>
                            <li>Code Status:Not yet documented</li>
                        </ul>
                        <ul>
                            <li>patient portal:No</li>
                            <li>ACO:</li>
                        </ul>
                    </div>
                </ul>
            </li>
        </ul>
    </nav>

    <section>
        <div class="sidebar">
            <div class="sub-navbar">
                <ul>
                    <li>
                        <a href="#"><svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24"
                                width="24px" fill="#000000">
                                <path d="M0 0h24v24H0z" fill="none" />
                                <path
                                    d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
                            </svg></a>
                        <a href="#">Menu</a>
                    </li>
                    <li> <a href="#"><svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24"
                                height="18px" viewBox="0 0 18 18" width="18px" fill="#000000">
                                <g>
                                    <rect fill="none" height="24" width="24" />
                                </g>
                                <g>
                                    <path
                                        d="M16,9V4l1,0c0.55,0,1-0.45,1-1v0c0-0.55-0.45-1-1-1H7C6.45,2,6,2.45,6,3v0 c0,0.55,0.45,1,1,1l1,0v5c0,1.66-1.34,3-3,3h0v2h5.97v7l1,1l1-1v-7H19v-2h0C17.34,12,16,10.66,16,9z"
                                        fill-rule="evenodd" />
                                </g>
                            </svg></a></li>
                </ul>
            </div>
            <div class="sub-navbar-topics">
                <ul>
                    <li>
                        <ul>
                            <li> <a href="#"> Orders</a></li>
                            <li> <a href="#"> +Add</a></li>
                        </ul>
                    </li>
                    <li> <a href="#">Results Review</a></li>
                    <li> <a href="#">Notes</a></li>
                    <li> <a href="#">Form Browser</a></li>
                    <li>
                        <ul>
                            <li> <a href="#">Allergies</a></li>
                            <li> <a href="#">+Add</a></li>
                        </ul>
                    </li>
                    <li> <a href="#">Diagnoses and Problems </a></li>
                    <li> <a href="#">Patient information </a> </li>
                    <li> <a href="#"> MAR</a> </li>
                    <li> <a href="#">MAR Summary</a> </li>
                    <li>
                        <ul>
                            <li> <a href="#">Medication List</a> </li>
                            <li> <a href="#">+Add</a> </li>
                        </ul>
                    </li>
                    <li>
                        <ul>
                            <li> <a href="#">Documentation</a> </li>
                            <li> <a href="#"> +Add</a></li>
                        </ul>
                    </li>
                    <li> <a href="#"> Flowsheet</a></li>
                    <li> <a href="#"> Pregnancy Summary Report</a></li>
                    <li> <a href="#">Newborn Discharge Information</a> </li>
                    <li> <a href="#">Infusion Billing Summary</a> </li>
                    <li> <a href="#">Observation Events</a> </li>
                </ul>
            </div>
        </div>

        <div class="main">
            <div class="main-navbar">
                <!-- <div class="div1">
                    <ul>
                        <li><a href="#"><svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24"
                                    width="24px" fill="#000000">
                                    <path d="M0 0h24v24H0z" fill="none" />
                                    <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
                                </svg></a></li>
                        <li><a href="#"><svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24"
                                    width="24px" fill="#000000">
                                    <path d="M0 0h24v24H0z" fill="none" />
                                    <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
                                </svg></a></li>
                        <li><a href="#"><svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24"
                                    width="24px" fill="#000000">
                                    <path d="M0 0h24v24H0z" fill="none" />
                                    <path d="M7 10l5 5 5-5z" />
                                </svg></a></li>
                        <li><a href="#"><svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24"
                                    width="24px" fill="#000000">
                                    <path d="M0 0h24v24H0z" fill="none" />
                                    <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
                                </svg></a></li>
                        <li><a href="#">Notes</a></li>
                    </ul>
                </div> -->
                <!-- <div class="div2">
                    <ul>
                        <li><a href="#"><svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24"
                                    width="24px" fill="#000000">
                                    <path d="M0 0h24v24H0z" fill="none" />
                                    <path
                                        d="M7 14H5v5h5v-2H7v-3zm-2-4h2V7h3V5H5v5zm12 7h-3v2h5v-5h-2v3zM14 5v2h3v3h2V5h-5z" />
                                </svg></a></li>
                        <li><a href="#">Full screen</a></li>
                        <li><a href="#"><svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24"
                                    width="24px" fill="#000000">
                                    <path d="M0 0h24v24H0z" fill="none" />
                                    <path
                                        d="M19 8H5c-1.66 0-3 1.34-3 3v6h4v4h12v-4h4v-6c0-1.66-1.34-3-3-3zm-3 11H8v-5h8v5zm3-7c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm-1-9H6v4h12V3z" />
                                </svg></a></li>
                        <li><a href="#">print</a></li>
                        <li><a href="#"><svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24"
                                    width="24px" fill="#000000">
                                    <path d="M0 0h24v24H0z" fill="none" />
                                    <path
                                        d="M12 4V1L8 5l4 4V6c3.31 0 6 2.69 6 6 0 1.01-.25 1.97-.7 2.8l1.46 1.46C19.54 15.03 20 13.57 20 12c0-4.42-3.58-8-8-8zm0 14c-3.31 0-6-2.69-6-6 0-1.01.25-1.97.7-2.8L5.24 7.74C4.46 8.97 4 10.43 4 12c0 4.42 3.58 8 8 8v3l4-4-4-4v3z" />
                                </svg></a></li>
                        <li><a href="#">0 minutes ago</a></li>
                    </ul>
                </div> -->
            </div>

            <div class="sub-main">
                <!-- <div class="sub-main-nav">
                    <ul>
                        <li><a href="#"><i class="fa fa-file-text-o" aria-hidden="true"></i></a></li>
                        <li><a href="#"><i class="fa fa-inbox" aria-hidden="true"></i></a></li>
                        <li><a href="#"><i class="fa fa-lightbulb-o" aria-hidden="true"></i></a></li>
                        <li><a href="#">|</a></li>
                        <li><a href="#"><i class="fa fa-file-image-o" aria-hidden="true"></i></a></li>
                        <li><a href="#"><i class="fa fa-flag" aria-hidden="true"></i></a></li>
                        <li><a href="#"><i class="fa fa-search" aria-hidden="true"></i></a></li>
                        <li><a href="#"><i class="fa fa-life-ring" aria-hidden="true"></i></a></li>
                        <li><a href="#">|</a></li>
                        <li><a href="#"><i class="fa fa-indent" aria-hidden="true"></i></a></li>
                        <li><a href="#">|</a></li>
                        <li><a href="#"><i class="fa fa-print" aria-hidden="true"></i></a></li>
                        <li><a href="#">|</a></li>
                        <li><a href="#"><i class="fa fa-ambulance" aria-hidden="true"></i></a></li>
                        <li><a href="#"><i class="fa fa-user" aria-hidden="true"></i></a></li>
                        <li><a href="#"><i class="fa fa-arrows" aria-hidden="true"></i></a></li>
                        <li><a href="#"><i class="fa fa-heart" aria-hidden="true"></i></a></li>
                    </ul>
                </div> -->
                <!-- <div class="title-bar">
                    <ul>
                        <li><svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px"
                                fill="#ffffff" style="
                        fill: black;background-color: #adb1b0;
                    ">
                                <path d="M14 7l-5 5 5 5V7z"></path>
                                <path d="M24 0v24H0V0h24z" fill="none"></path>
                            </svg></li>
                        <li>Last 500 Documents: 11 out of 11 documents are accessible. (Document Count) In Error
                            Documents Filtered</li>
                        <li><svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px"
                                fill="white" style="
                        fill: black;
                        background-color: #adb1b0;
                    ">
                                <path d="M10 17l5-5-5-5v10z"></path>
                                <path d="M0 24V0h24v24H0z" fill="none"></path>
                            </svg></li>
                    </ul>
                </div> -->
                <div class="works">
                    <div class="sidetag"></div>
                    <div class="text-space">
                        <iframe [src]="pdfUrl" width="100%" height="500px">
                        </iframe>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
<!-- <div #nonEhr>
    <iframe [src]="pdfUrl" width="100%" height="500px">
    </iframe>
</div> -->

