import { Injectable } from "@angular/core";
import {BehaviorSubject, Observable, Subject} from "rxjs";
import {chartHistoryDetails} from "../../coding/data/chart-history";

@Injectable({
  providedIn: "root"
})
export class DataService {
  private chartDetails = new Subject<any>();
  chartDetails$ = this.chartDetails.asObservable();
  private action =  new Subject<any>();
  action$ = this.action.asObservable();
  private chartHeaderInfo = new Subject<any>();
  chartHeaderInfo$ = this.chartHeaderInfo.asObservable();
  private viewMode = new Subject<any>();
  viewMode$ = this.viewMode.asObservable();
  private bulkUpdateDetailsForCoder = new Subject<any>();
  bulkUpdateDetailsForCoder$ = this.bulkUpdateDetailsForCoder.asObservable();
  public bulkDataObjForCoder: { coderStatus: any, coderComments: any, isCommentMandatory: number, close: false, statusCategory: 0, displayCoderStatus: any};
  public bulkDataObjForQA: {  auditStatus: any, auditComments: any, isCommentMandatory: number, close: false, statusCategory: 0, displayCoderStatus: any};
  private bulkUpdateDetailsForQA = new Subject<any>();
  bulkUpdateDetailsForQA$ = this.bulkUpdateDetailsForQA.asObservable();
  public summaryDataArray2 = {};
  summaryDataArray2$ = {};
  private dosData = new Subject<any>();
  dosData$ = this.dosData.asObservable();
  private noHccData = new Subject<any>();
  noHccData$ = this.noHccData.asObservable();
  private copiedData = new Subject<any>();
  copiedData$ = this.copiedData.asObservable();
  private workQueueCommonCriteria = new Subject<any>();
  workQueueCommonCriteria$ = this.workQueueCommonCriteria.asObservable();
  private pdfData = new Subject<any>();
  pdfData$ = this.pdfData.asObservable();
  private updateSummarySectionItems =  new Subject<any>();
  updateSummarySectionItems$ = this.updateSummarySectionItems.asObservable();
  private chartWindowRef = new Subject<any>();
  chartWindowRef$ = this.chartWindowRef.asObservable();
  private sentenceData = new BehaviorSubject<any>(null);
  sentenceData$ = this.sentenceData.asObservable();

  constructor() { }

  sharePDF(PDF) {
    this.pdfData.next(PDF);
  }

  shareChartWindowReference(chartWindow) {
    this.chartWindowRef.next(chartWindow);
  }

  shareDOS(data) {
    this.dosData.next(data);
  }

  shareNOHCCData(data) {
    this.noHccData.next(data);
  }

  shareViewMode(isReadOnly) {
    this.viewMode.next(isReadOnly);
  }

  shareChartData(data) {
    this.chartDetails.next(data);
  }

  shareCopiedChartData(data) {
    this.copiedData.next(data);
  }

  shareAction(chartAction, data) {
    let dataObj = {action: chartAction, year: data};
    this.action.next(dataObj);
  }

  shareChartInformation(data) {
    this.chartHeaderInfo.next(data);
  }

  shareBulkUpdateDataForCoder(status, comment, isSecondaryMandatoryFlag, closed, category, displayCoderStatus) {
    this.bulkDataObjForCoder = {coderStatus: status, coderComments: comment, isCommentMandatory: isSecondaryMandatoryFlag, close: closed, statusCategory: category, displayCoderStatus: displayCoderStatus};
    this.bulkUpdateDetailsForCoder.next(this.bulkDataObjForCoder);
  }

  shareBulkUpdateDataForQA(status, comment, isSecondaryMandatoryFlag, closed, category, displayCoderStatus) {
    this.bulkDataObjForQA = {auditStatus: status, auditComments: comment, isCommentMandatory: isSecondaryMandatoryFlag, close: closed, statusCategory: category, displayCoderStatus: displayCoderStatus};
    this.bulkUpdateDetailsForQA.next(this.bulkDataObjForQA);
  }

  updateSummarySection(dosCurrentData, dosYear) {
    let newData = {count: dosCurrentData.length, result: dosCurrentData};
    this.summaryDataArray2[dosYear].next(newData);
  }

  createNewSummaryObservable(dosYear) {
    let subject1 = new BehaviorSubject<any>(null);
    let observable1 = subject1.asObservable();
    if (dosYear in this.summaryDataArray2) {

    } else {
      this.summaryDataArray2[dosYear] = subject1;
      this.summaryDataArray2$[dosYear] = observable1;
    }
    return this.summaryDataArray2[dosYear];
  }

  subscribeSummaryObservable(dosYear): Observable<any> {
    return this.summaryDataArray2$[dosYear];
  }


  removeSummaryObservable(dosYear) {
    if (dosYear in this.summaryDataArray2$) {
      delete this.summaryDataArray2$[dosYear];
    }
    if (dosYear in this.summaryDataArray2) {
      delete this.summaryDataArray2[dosYear];
    }
  }

  shareWorkQueueCommonCriteriaOption(isCommonCriteriaSelected, dialogRef) {
    const commonCriteriaObject = {isCommonCriteriaSelected, dialogRef};
    this.workQueueCommonCriteria.next(commonCriteriaObject);
  }

  updateSummarySectionItem(arrayItem: any) {
    this.updateSummarySectionItems.next(arrayItem);
  }

  shareSentenceData(sentence:string){
    this.sentenceData.next(sentence)
  }
}
