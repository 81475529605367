export class IClient {
  id: number;
  typeOfClient: string;
  name: string;
  accountManagerFirstName: string;
  accountManagerLastName: string;
  accountManagerPhoneNumber: number;
  accountManagerEmail: string;
  status: number;
  createdDate: string;
  deleted: boolean;
  logo: string;
  createdBy: number;
  providerCustom1Label: string;
  providerCustom2Label: string;
  providerCustom1Required: boolean;
  providerCustom2Required: boolean;
}
