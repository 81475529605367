import { Component } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import {Title} from "@angular/platform-browser";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
})
export class AppComponent {
  constructor(translate: TranslateService, private titleService: Title) {
    this.setTitle("EPP");
    translate.addLangs(["en", "fr"]);
    translate.setDefaultLang("en");

    const browserLang: string = translate.getBrowserLang();
    translate.use(browserLang.match(/en|fr/) ? browserLang : "en");
  }

  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }
}
