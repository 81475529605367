import { Injectable } from "@angular/core";
import {API_CURRENT_VERSION, EndPointBaseUrl} from "../../../common/settings/settings";
import {CommunicatorService} from "../../../common/services/communicator.service";
import {NotificationService} from "../../../common/services/notification.service";
import {ErrorMessageService} from "../../../common/services/error-message.service";
import {IUser} from "../model/user";
import {Subject} from "rxjs";

@Injectable({
  providedIn: "root"
})
export class ModifyUserService {
  public newUser: IUser;
  public newUserData = new Subject<IUser>();
  public newUserData$ = this.newUserData.asObservable();
  public modifyUserData = new Subject<any>();
  public modifyUserData$ = this.modifyUserData.asObservable();
  public modifySupervisor = new Subject<any>();
  public modifySupervisor$ = this.modifySupervisor.asObservable();
  public newSupervisor;
  public newSupervisorData = new Subject<any>();
  public newSupervisorData$ = this.newSupervisorData.asObservable();
  public updateUserDataList: IUser;
  public updateUserDetailsList = new Subject<any>();
  public updateUserDetailsList$ = this.updateUserDetailsList.asObservable();

  constructor(private communicatorServiceObject: CommunicatorService,
              public notificationService: NotificationService,
              public errorMessageService: ErrorMessageService) { }

  createNewUser(userData) {
    if (userData !== undefined && userData !== null) {
      this.communicatorServiceObject.postData(EndPointBaseUrl + API_CURRENT_VERSION +
        "/users", JSON.stringify(userData)).subscribe(data => {
          if (data !== undefined && data !== null) {
            if (data.isActive === false) {
              let successMessage = "";
              successMessage = "Inactive user has been created successfully.";
              this.notificationService.showSuccess(successMessage);
            } else if (data.isActive === true) {
              let successMessage = "";
              successMessage = "Active user has been created successfully.";
              this.notificationService.showSuccess(successMessage);
            }
            this.newUser = data;
            this.newUserData.next(this.newUser);
          }
        }, error => {
        if (error.statusCode !== undefined && error.statusCode !== null && error.statusCode === 500) {
          this.notificationService.showError("Failed to process your request");
        } else {
          const manipulatedErrorMessage = this.errorMessageService.manipulateErrorMessageForDisplayingOnSnackBarWithoutKey(error);
          if (manipulatedErrorMessage !== undefined && manipulatedErrorMessage !== null && manipulatedErrorMessage !== '') {
            this.notificationService.showError(manipulatedErrorMessage);
          }
        }
        }
      );
    }
  }

  updateUserData(userId, userData) {
    let queryParams = {};
    queryParams = queryParams || {};
    let queryString = Object.keys(queryParams).map(key => encodeURIComponent(key) + "=" + encodeURIComponent(queryParams[key])).join("&");
    if (queryString !== "") {
      queryString = "?" + queryString;
    }
    this.communicatorServiceObject.updateData(EndPointBaseUrl + API_CURRENT_VERSION +
      "/users/" + userId + queryString, JSON.stringify(userData)).subscribe(data => {
        if (data !== undefined && data !== null) {
          this.updateUserDataList = data;
          const successMessage = "Changes updated successfully";
          this.notificationService.showSuccess(successMessage);
          this.updateUserDetailsList.next(this.updateUserDataList);
        }
      }, error => {
      if (error.statusCode !== undefined && error.statusCode !== null && error.statusCode === 500) {
        this.notificationService.showError("Failed to process your request");
      } else {
        const manipulatedErrorMessage = this.errorMessageService.manipulateErrorMessageForDisplayingOnSnackBarWithoutKey(error);
        if (manipulatedErrorMessage !== undefined && manipulatedErrorMessage !== null && manipulatedErrorMessage !== '') {
          this.notificationService.showError(manipulatedErrorMessage);
        }
      }
      }
    );
  }

  reassignSupervisor(userId, supervisor , newSupervisorId) {
    const userData = {};
    userData['supervisor'] = newSupervisorId;
    // todo call api to change supervisor of users
    this.communicatorServiceObject.updateData(EndPointBaseUrl + API_CURRENT_VERSION +
      "/users/" + userId + "/" + supervisor, JSON.stringify(userData)).subscribe(data => {
      if (data !== undefined && data !== null) {
          this.newSupervisor = data;
          this.newSupervisorData.next(this.newSupervisor);
        }
      }, error => {
      if (error.statusCode !== undefined && error.statusCode !== null && error.statusCode === 500) {
        this.notificationService.showError("Failed to process your request");
      } else {
        const manipulatedErrorMessage = this.errorMessageService.manipulateErrorMessageForDisplayingOnSnackBarWithoutKey(error);
        if (manipulatedErrorMessage !== undefined && manipulatedErrorMessage !== null && manipulatedErrorMessage !== '') {
          this.notificationService.showError(manipulatedErrorMessage);
        }
      }
      }
    );
  }
}
