import {Injectable} from "@angular/core";
import {Subject} from "rxjs";
import {API_CURRENT_VERSION, EndPointBaseUrl} from "../../common/settings/settings";
import {CommunicatorService} from "../../common/services/communicator.service";
import {NotificationService} from "../../common/services/notification.service";
import {ErrorMessageService} from "../../common/services/error-message.service";
import {IGuidelines} from "../../guidelines/management/model/guidelines-model";
import {SelectClientService} from "../../client/management/select-client/select-client.service";

@Injectable({
  providedIn: "root"
})
export class CodingGuidelinesDialogService {
  public guidelineList = Array<IGuidelines>();
  public codingGuidelinesList = new Subject<any>();
  public codingGuidelinesList$ = this.codingGuidelinesList.asObservable();
  public userFullName: string;
  public guidelinesDocumentIndex = 0;

  constructor(private communicatorServiceObject: CommunicatorService,
              public notificationService: NotificationService,
              public errorMessageService: ErrorMessageService,
              public selectClientService: SelectClientService) {
    this.selectClientService.selectedClientData$.subscribe(data => {
      this.guidelineList = [];
    });
  }

  getPublishedGuidelinesList(clientId, published, ordering) {
    let queryParams = {};
    if (clientId !== undefined && clientId !== null) {
      queryParams['clientId'] = clientId;
    }
    if (published !== undefined && published !== null) {
      queryParams['published'] = published;
    }
    if (ordering !== undefined && ordering !== null) {
      queryParams['ordering'] = ordering;
    }
    queryParams = queryParams || {};
    let queryString = Object.keys(queryParams).map(key => encodeURIComponent(key) + "=" + encodeURIComponent(queryParams[key])).join("&");
    if (queryString !== "") {
      queryString = "?" + queryString;
    }
    this.communicatorServiceObject.getData(EndPointBaseUrl + API_CURRENT_VERSION +
      "/guidelines" + queryString).subscribe(data => {
        if (data !== undefined && data !== null) {
          this.guidelineList = data.results;
          this.convertListData(this.guidelineList);
          this.codingGuidelinesList.next(this.guidelineList);
        }
      }, error => {
        if (error.statusCode !== undefined && error.statusCode !== null && error.statusCode === 500) {
          this.notificationService.showError("Failed to process your request");
        } else {
          const manipulatedErrorMessage = this.errorMessageService.manipulateErrorMessageForDisplayingOnSnackBarWithoutKey(error);
          if (manipulatedErrorMessage !== undefined && manipulatedErrorMessage !== null && manipulatedErrorMessage !== '') {
            this.notificationService.showError(manipulatedErrorMessage);
          }
        }
      }
    );
  }

  convertListData(data) {
    if (data !== undefined && data !== null && data.length > 0) {
      // tslint:disable-next-line:prefer-for-of forin
      for (const dataItem in data) {
        if (data[dataItem].uploadedBy !== undefined && data[dataItem].uploadedBy !== null) {
          data[dataItem].uploadedByName = this.getFullName(data[dataItem].uploadedBy);
        }
        if (data[dataItem].dateUploaded !== undefined && data[dataItem].dateUploaded !== null) {
          data[dataItem].dateUploaded = new Date(data[dataItem].dateUploaded);
        }
      }
    }
  }

  getFullName(userArray) {
    this.userFullName = "";
    if (userArray.firstName !== undefined && userArray.firstName !== null && userArray.firstName !== "") {
      this.userFullName = this.userFullName.concat((userArray.firstName).toString()) + " ";
    }
    if (userArray.lastName !== undefined && userArray.lastName !== null && userArray.lastName !== "") {
      this.userFullName = this.userFullName.concat((userArray.lastName).toString());
    }
    return this.userFullName;
  }
}
