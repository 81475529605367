import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef,MAT_DIALOG_DATA  } from '@angular/material/dialog';

@Component({
  selector: 'app-sentence-replace-dialog',
  templateUrl: './sentence-replace-dialog.component.html',
  styleUrls: ['./sentence-replace-dialog.component.scss']
})
export class SentenceReplaceDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<SentenceReplaceDialogComponent>,@Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}

export interface DialogData {
  viewData: string;
  }