import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { DataService } from "../../services/data.service";
import { IChart } from "../../../coding/Models/chartv2";
import { Router } from '@angular/router';

@Component({
  selector: 'app-common-dialog',
  templateUrl: './common-dialog.component.html',
  styleUrls: ['./common-dialog.component.scss']
})
export class CommonDialogComponent implements OnInit {
  public messageToBeDisplayed: string;
  public isConfirmationPopup: boolean;
  public actionPerformed: string;
  public chartData: IChart;

  constructor(@Optional() @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<CommonDialogComponent>,
    private dataService: DataService, private router: Router) { }

  ngOnInit(): void {
    if (this.data !== undefined && this.data !== null) {
      if (this.data['messages'] !== undefined && this.data['messages'] !== null) {
        this.messageToBeDisplayed = this.data['messages'];
      }
      if (this.data['confirmation'] !== undefined && this.data['confirmation'] !== null) {
        this.isConfirmationPopup = this.data['confirmation'];
      }
      if (this.data['action'] !== undefined && this.data['action'] !== null) {
        this.actionPerformed = this.data['action'];
      }
      if (this.data['chartData'] !== undefined && this.data['chartData'] !== null) {
        this.chartData = this.data['chartData'];
      }
    }
  }

  closePopUp() {
    if (this.actionPerformed === 'QuerySubmit') {
      this.router.navigate(["/project"], {
        queryParams: { roleId: this.data.roleId }
      })
    }
    this.dialogRef.close();
  }

  closeButtonPopUp() {
    if (this.actionPerformed === 'QuerySubmit') {
      this.router.navigate(["/project"], {
        queryParams: { roleId: this.data.roleId }
      })
    }
    this.dialogRef.close();
  }

  onClickYesButton() {
    if (this.actionPerformed === 'unsavedInformation') {
      this.dataService.shareAction("unsavedInformation", null);
      this.dialogRef.close();
    } else if (this.actionPerformed === 'NoHccChart') {
      this.dialogRef.close();
      this.dataService.shareAction("No HCC", null);
      this.dataService.shareChartData(this.chartData);
    } else if (this.actionPerformed === 'Clear') {
      this.dialogRef.close();
      this.dataService.shareAction("Clear", null);
    } else if (this.actionPerformed === 'navigatingAway') {
      const data = { nextState: this.data['nextState'] };
      this.dataService.shareAction("NavigatingAway", data);
      this.dialogRef.close();
    } else if (this.actionPerformed === 'closeButtonClicked') {
      this.dataService.shareAction("closeButtonClicked", null);
      this.dialogRef.close();
    } else if (this.actionPerformed === 'logOut') {
      this.dataService.shareAction("logOut", null);
      this.dialogRef.close();
    } else if (this.actionPerformed === 'QuerySubmit') {
      this.dataService.shareAction("QuerySubmit", null);
      this.dialogRef.close();
    }
  }

}
