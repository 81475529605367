import {Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {MatDialogRef} from "@angular/material/dialog";
import {CodingGuidelinesDialogService} from "./coding-guidelines-dialog.service";
import {Subscription} from "rxjs";
import {TextWrapSettingsModel} from "@syncfusion/ej2-grids";
import {SelectClientService} from "../../client/management/select-client/select-client.service";
import {IGuidelines} from "../../guidelines/management/model/guidelines-model";
import {GuidelinesService} from "../../guidelines/guidelines.service";
import {MaxLimitForGuidelinesDocument} from "../../common/settings/settings";
import {Router} from "@angular/router";
import {GridComponent} from "@syncfusion/ej2-angular-grids";

@Component({
  selector: "app-coding-guidelines",
  templateUrl: "./coding-guidelines-dialog.component.html",
  styleUrls: ["./coding-guidelines-dialog.component.scss"]
})
export class CodingGuidelinesDialogComponent implements OnInit, OnDestroy {
  public codingGuidelinesList: IGuidelines[];
  public guidelineList$: Subscription;
  public dateFormat: { type: string, format: string };
  public wrapSettings: TextWrapSettingsModel;
  public selectedClient: number;
  public userId: number;
  public clientId: number;
  @ViewChild("codingGuidelinesGrid")
  public codingGuidelinesGrid: GridComponent;

  constructor(private dialogRef: MatDialogRef<CodingGuidelinesDialogComponent>,
              public codingGuidelinesService: CodingGuidelinesDialogService,
              public selectClientService: SelectClientService,
              private router: Router) { }

  ngOnInit() {
    this.checkPreCondition();
    this.wrapSettings = { wrapMode: "Header" };
    this.dateFormat = {type: "date", format: "MM/dd/yyyy"};
  }

  checkPreCondition() {
    const userId = sessionStorage.getItem("userId");
    const clientId = sessionStorage.getItem("activeClient");
    if (userId === undefined || userId === null) {
      this.userId = Number(userId);
      this.router.navigate(["/session/signin"]);
    } else if (clientId === undefined || clientId === null) {
      this.router.navigate(["/client"]);
    } else {
      this.clientId = Number(clientId);
      if (this.clientId !== undefined && this.clientId !== null) {
        this.getGuidelinesList(this.clientId, "-date_uploaded");
      }
    }
  }

  getGuidelinesList(clientId, ordering) {
    this.codingGuidelinesService.getPublishedGuidelinesList(clientId, true, ordering);
    this.guidelineList$ = this.codingGuidelinesService.codingGuidelinesList$.subscribe(data => {
      if (data !== undefined && data !== null && data.length > 0) {
        this.codingGuidelinesList = data;
      } else {
        this.codingGuidelinesList = [];
        (this.codingGuidelinesGrid.localeObj as any).localeStrings.EmptyRecord = "No Records To Display";
      }
    });
  }

  ngOnDestroy() {
    if (this.guidelineList$) {
      this.guidelineList$.unsubscribe();
    }
  }

  closeCodingGuidelinesPopup() {
    this.dialogRef.close();
  }

  openDocument(documentURL) {
    window.open(documentURL, "guideline-document-" + this.codingGuidelinesService.guidelinesDocumentIndex % MaxLimitForGuidelinesDocument,
      "scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,\n" +
      "width=0,height=0,left=-1000,top=-1000");
    this.codingGuidelinesService.guidelinesDocumentIndex = this.codingGuidelinesService.guidelinesDocumentIndex + 1;
  }

  onLoadCodingGuidelinesGrid() {
    (this.codingGuidelinesGrid.localeObj as any).localeStrings.EmptyRecord = "Fetching Data...";
  }

}
