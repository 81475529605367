<div fxLayout="row wrap" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container"
  cdkDragHandle class="dialog-drag">
  <h2 class="reset-chart-heading"
    *ngIf="actionPerformed !== undefined && actionPerformed !== null && (actionPerformed === 'navigatingAway' || actionPerformed === 'closeButtonClicked' || actionPerformed === 'Clear')">
    Alert</h2>
  <div fxFlex></div>
  <span>
    <button mat-icon-button matTooltip="Click here to close the popup" (click)="closePopUp()">
      <mat-icon>close</mat-icon>
    </button>
  </span>
</div>

<mat-dialog-content>
  {{messageToBeDisplayed}}
</mat-dialog-content>
<mat-dialog-actions align="end">
  <div *ngIf="isConfirmationPopup === false">
    <button mat-raised-button color="primary" (click)="closePopUp()">Ok</button>
  </div>
  <div *ngIf="isConfirmationPopup === true">
    <button mat-raised-button color="primary" (click)="closeButtonPopUp()">No</button>
    <button mat-raised-button (click)="onClickYesButton()">Yes</button>
  </div>
</mat-dialog-actions>