import {Injectable} from "@angular/core";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ConfigurationService} from "./configuration.service";

@Injectable({
  providedIn: "root"
})
export class ErrorMessageService {

  constructor() {

  }

  /**
   * Mapping key and value of all type of error messages coming from the API.
   * @param error: error coming from the API
   */
  manipulateErrorMessageForDisplayingOnSnackBar(error) {
    let formattedMessage: any;
    if (error !== undefined && error !== null) {
      if (error.statusCode !== undefined && error.statusCode !== null && error.statusCode === 0) {
        formattedMessage = "Connection Lost. Please check your Internet connection";
      } else if (error.statusCode !== undefined && error.statusCode !== null && error.statusCode === 500) {
        formattedMessage = "Failed to process your request";
      } else if (error.statusCode !== undefined && error.statusCode !== null && error.statusCode === 504) {
        formattedMessage = "";
      } else if (error.statusCode !== undefined && error.statusCode !== null && error.statusCode === 502) {
        formattedMessage = "";
      } else {
        if(error.message !== undefined && error.message !== null) {
          for (let [key, value] of Object.entries(error.message)) {
            if (Array.isArray(value)) {
              formattedMessage = key += ", " + value;
            } else {
              formattedMessage = value;
            }
          }
        }
      }
      return formattedMessage;
    }
  }

  /**
   * Mapping key and value of all type of error messages coming from the API.
   * @param error: error coming from the API
   */
  manipulateErrorMessageForDisplayingOnSnackBarWithoutKey(error) {
    let formattedMessage: any;
    if (error !== undefined && error !== null) {
      if (error.statusCode !== undefined && error.statusCode !== null && error.statusCode === 0) {
        formattedMessage = "Connection Lost. Please check your Internet connection";
      } else if (error.statusCode !== undefined && error.statusCode !== null && error.statusCode === 500) {
        formattedMessage = "Failed to process your request";
      } else if (error.statusCode !== undefined && error.statusCode !== null && error.statusCode === 504) {
        formattedMessage = "";
      } else if (error.statusCode !== undefined && error.statusCode !== null && error.statusCode === 502) {
        formattedMessage = "";
      } else {
        if (error.message !== undefined && error.message !== null) {
          for (let [key, value] of Object.entries(error.message)) {
            if (Array.isArray(value)) {
              formattedMessage = value;
            } else {
              formattedMessage = value;
            }
          }
        }
      }
      return formattedMessage;
    }
  }
}
