import { API_CURRENT_VERSION, EndPointBaseUrl } from "src/app/common/settings/settings";

export const CONCURRENT_CONSTANTS = {
    API_BASE_URL: EndPointBaseUrl + API_CURRENT_VERSION,
    API: {
        GET_CHART_DATA: "assets/mockups/concurrent-data.json",
        GET_NODE_TYPES: "assets/mockups/noteTypes.json",
        GET_POS_DATA: "assets/mockups/POSData.json",
        GET_PROVIDER_LIST: "assets/mockups/providerList.json",
        GET_DX_CODE_LIST: "assets/mockups/DxCodeNew.json",
        GET_CODE_STATUS: "assets/mockups/concurrent_code_status.json",
        GET_ICD10_CODES: "assets/mockups/ICD10ToHCCMappingNew.json",
        GET_CODING_COMMENTS: "assets/mockups/concurrent_code_comment.json",
        GET_PROVIDER_QUERY: "/providers/queries"

    }
}

export class Temp {
  public static data = false;
  public static signout: boolean;
  public static submitChart = false;
}

