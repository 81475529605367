import {Injectable} from "@angular/core";
import {API_CURRENT_VERSION, EndPointBaseUrl} from "../../../common/settings/settings";
import {CommunicatorService} from "../../../common/services/communicator.service";
import {NotificationService} from "../../../common/services/notification.service";
import {ErrorMessageService} from "../../../common/services/error-message.service";
import {Subject} from "rxjs";
import {IUser} from "../model/user";

@Injectable({
  providedIn: "root"
})
export class ModifyUserProfileService {
  public updatedProfileUser: IUser;
  public updateUserProfileData = new Subject<any>();
  public updateUserProfileData$ = this.updateUserProfileData.asObservable();

  constructor(private communicatorServiceObject: CommunicatorService,
              public notificationService: NotificationService,
              public errorMessageService: ErrorMessageService) {
  }

  updateProfile(userData, userId) {
    this.communicatorServiceObject.patchData(EndPointBaseUrl + API_CURRENT_VERSION +
      "/users/" + userId, JSON.stringify(userData)).subscribe(data => {
        if (data !== undefined && data !== null) {
          this.updatedProfileUser = data;
          const successMessage = "Changes updated successfully";
          this.notificationService.showSuccess(successMessage);
          this.updateUserProfileData.next(this.updatedProfileUser);
        }
      }, error => {
        if (error.statusCode !== undefined && error.statusCode !== null && error.statusCode === 500) {
          this.notificationService.showError("Failed to process your request");
        } else {
          const manipulatedErrorMessage = this.errorMessageService.manipulateErrorMessageForDisplayingOnSnackBar(error);
          if (manipulatedErrorMessage !== undefined && manipulatedErrorMessage !== null && manipulatedErrorMessage !== '') {
            this.notificationService.showError(manipulatedErrorMessage);
          }
        }
      }
    );
  }
}
