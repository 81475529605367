<div fxLayout="row wrap" cdkDrag cdkDragRootElement=".cdk-overlay-pane"
     cdkDragBoundary=".cdk-overlay-container" cdkDragHandle class="dialog-drag">
  <h2 class="reset-chart-heading">Alert</h2>
  <div fxFlex></div>
  <span>
    <button mat-icon-button matTooltip="Click here to close the popup"
            (click)="closePopUp()">
     <mat-icon>close</mat-icon>
    </button>
  </span>
</div>

<mat-dialog-content>
  {{messageToBeDisplayed}}
</mat-dialog-content>
<mat-dialog-actions align="end">
  
  <div>
    <button mat-raised-button color="primary" (click)="closePopUp()">No</button>
    <button mat-raised-button mat-dialog-close ="yes" cdkFocusInitial>Yes</button>
  </div>
</mat-dialog-actions>
