import {Injectable} from '@angular/core';
import {API_CURRENT_VERSION, EndPointBaseUrl} from "../settings/settings";
import {Subject} from "rxjs";
import {CommunicatorService} from "./communicator.service";
import {NotificationService} from "./notification.service";
import {ErrorMessageService} from "./error-message.service";

@Injectable({
  providedIn: 'root'
})
export class TimezoneService {
  public listOfTimezone = [];
  public timezoneList = new Subject<any>();
  public timezoneList$ = this.timezoneList.asObservable();

  constructor(private communicatorServiceObject: CommunicatorService,
              public notificationService: NotificationService,
              public errorMessageService: ErrorMessageService) {
  }

  getTimezoneList() {
    if (this.listOfTimezone !== undefined && this.listOfTimezone !== null && this.listOfTimezone.length > 0) {
      this.timezoneList.next(this.listOfTimezone);
    } else {
      this.communicatorServiceObject.getData(EndPointBaseUrl + API_CURRENT_VERSION +
        "/timezones").subscribe(data => {
          if (data !== null && data !== undefined) {
            this.listOfTimezone = data.results;
            this.timezoneList.next(this.listOfTimezone);
          }
        }, error => {
        if (error.statusCode !== undefined && error.statusCode !== null && error.statusCode === 500) {
          this.notificationService.showError("Failed to process your request");
        } else {
          const manipulatedErrorMessage = this.errorMessageService.manipulateErrorMessageForDisplayingOnSnackBarWithoutKey(error);
          if (manipulatedErrorMessage !== undefined && manipulatedErrorMessage !== null && manipulatedErrorMessage !== '') {
            this.notificationService.showError(manipulatedErrorMessage);
          }
        }
        }
      );
    }
  }
}
