import { EventEmitter, Injectable, Output, Directive } from "@angular/core";

@Directive()
@Injectable({
  providedIn: "root"
})
export class AuthService {
  @Output() change: EventEmitter<boolean> = new EventEmitter();
  authenticated = false;

  constructor() {
    this.authenticated = sessionStorage.getItem("authenticated") === "true";
  }

  public signOut(): boolean {
    // call Logout api
    this.authenticated = false;
    sessionStorage.setItem("authenticated", String(this.authenticated));
    return this.authenticated;
  }

  public reLogin(): boolean {
    this.authenticated = false;
    sessionStorage.setItem("authenticated", String(this.authenticated));
    return this.authenticated;
  }

  public signIn(): boolean {
    // call SignIn api and if successful update authenticated to true
    this.authenticated = true;
    sessionStorage.setItem("authenticated", String(this.authenticated));
    this.change.emit(this.authenticated);
    return this.authenticated;
  }


  /**
   * extend existing session if available otherwise create new
   */
  public extendSession(): boolean {
    // call session update if successful return true otherwise - route to signin, preserve where user was trying to go
    return true;
  }

  /**
   * renew (create new session) without destroying old one
   */
  public renewSession(): boolean {
    // call session renew if successful return true otherwise - route to signin, preserve where user was trying to go
    return true;
  }

}
