import {Component, Inject, OnInit, Optional} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ModifyUserService} from "../modify-user.service";

@Component({
  selector: "app-external-user-dialog",
  templateUrl: "./external-user-dialog.component.html",
  styleUrls: ["./external-user-dialog.component.scss"]
})
export class ExternalUserDialogComponent implements OnInit {

  constructor(@Optional() @Inject(MAT_DIALOG_DATA) public data,
              private dialogRef: MatDialogRef<ExternalUserDialogComponent>,
              private modifyUserService: ModifyUserService) { }

  ngOnInit(): void {
  }

  closePopUp($event: MouseEvent) {
    if (this.data.totalClients > 1) {
      this.modifyUserService.modifyUserData.next({action: "change.user.type", value: "internal"});
    }
    this.dialogRef.close();
  }

  onConfirm($event: MouseEvent) {
    if (this.data.totalClients > 1) {
      this.modifyUserService.modifyUserData.next({action: "change.user.type", value: "internal"});
    } else {
      this.modifyUserService.modifyUserData.next({action: "change.user.type", value: "external"});
    }
    this.dialogRef.close();
  }
}
