import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LoggingService {

    logError(message: string, stack: string) {
        // todo if.. console.log("LoggingService: " + message);
    }
}
