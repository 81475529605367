<div cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container" cdkDragHandle
  class="dialog-drag">
  <div fxLayout="row wrap" fxLayoutAlign="end">
    <span>
      <button data-testid="Close" mat-icon-button matTooltip="Click here to close the popup"
        (click)="closeCodingGuidelinesPopup()">
        <mat-icon>close</mat-icon>
      </button>
    </span>
  </div>
</div>
<div class="control-section grid-display ecp-ejgrid-tight ecp-ejgrid-eclat-white ecp-ejgrid-background-navy">
  <ejs-grid #codingGuidelinesGrid [dataSource]="codingGuidelinesList" gridLines="Both" allowTextWrap='true'
    (load)="onLoadCodingGuidelinesGrid()" [textWrapSettings]="wrapSettings" height="370">
    <e-columns>
      <e-column field='documentId' headerText='Document ID' width="110" textAlign="center">
        <ng-template #template let-data>
          <a class="link" matTooltip="{{data.documentId}}" (click)="openDocument(data.document)">{{data.documentId}}</a>
        </ng-template>
      </e-column>
      <e-column field='dateUploaded' headerText='Date Uploaded' width="100" [format]="dateFormat" type="date"
        textAlign="center">
      </e-column>
      <e-column field='documentName' headerText='Document Name' textAlign="center">
        <ng-template #template let-data>
          <span matTooltip="{{data.documentName}}">{{data.documentName}}</span>
        </ng-template>
      </e-column>
      <e-column field='effectiveDate' headerText='Effective Date' width="100" [format]="dateFormat" type="date"
        textAlign="center">
      </e-column>
      <e-column field='uploadedByName' headerText='Uploaded By' width="120" textAlign="center">
        <ng-template #template let-data>
          <span matTooltip="{{data.uploadedByName}}">{{data.uploadedByName}}</span>
        </ng-template>
      </e-column>
    </e-columns>
  </ejs-grid>
</div>