import {Injectable} from "@angular/core";
import {API_CURRENT_VERSION, EndPointBaseUrl} from "../../../common/settings/settings";
import {CommunicatorService} from "../../../common/services/communicator.service";
import {NotificationService} from "../../../common/services/notification.service";
import {ErrorMessageService} from "../../../common/services/error-message.service";
import {Subject} from "rxjs";
import {DatePipe} from "@angular/common";
import {SelectClientService} from "../../../client/management/select-client/select-client.service";
import {hideSpinner} from "@syncfusion/ej2-popups";

@Injectable({
  providedIn: "root"
})
export class ListAlertsService {
  public alertsList = [];
  public allAlertsList = new Subject<any>();
  public allAlertsList$ = this.allAlertsList.asObservable();
  public activeAlertsList = [];
  public allActiveAlertsList = new Subject<any>();
  public allActiveAlertsList$ = this.allActiveAlertsList.asObservable();
  public userFullName: string;
  public dateTimeRange: string;
  public startDateTime: string;
  public endDateTime: string;
  public activeSystemAlertsList = [];
  public allActiveSystemAlertsList = new Subject<any>();
  public allActiveSystemAlertsList$ = this.allActiveSystemAlertsList.asObservable();

  constructor(private communicatorServiceObject: CommunicatorService,
              public notificationService: NotificationService,
              public errorMessageService: ErrorMessageService,
              private datePipe: DatePipe,
              public selectClientService: SelectClientService) {

    this.selectClientService.selectedClientData$.subscribe(data => {
      if (data !== undefined && data !== null) {
        this.alertsList = [];
        this.activeAlertsList = [];
      }
    });
  }

  getAllAlertsList() {
    let queryParams = {};
    queryParams['ordering'] = "new_priority,start_time";
    queryParams = queryParams || {};
    let queryString = Object.keys(queryParams).map(key => encodeURIComponent(key) + "=" + encodeURIComponent(queryParams[key])).join("&");
    if (queryString !== "") {
      queryString = "?" + queryString;
    }
    this.communicatorServiceObject.getData(EndPointBaseUrl + API_CURRENT_VERSION +
      "/alerts" + queryString).subscribe(data => {
        if (data !== undefined && data !== null && data.results.length > 0) {
          this.alertsList = data.results;
          this.convertListData(this.alertsList);
          this.allAlertsList.next(this.alertsList);
        }
      }, error => {
        if (error.statusCode !== undefined && error.statusCode !== null && error.statusCode === 500) {
          this.notificationService.showError("Failed to process your request");
        } else {
          const manipulatedErrorMessage = this.errorMessageService.manipulateErrorMessageForDisplayingOnSnackBar(error);
          if (manipulatedErrorMessage !== undefined && manipulatedErrorMessage !== null && manipulatedErrorMessage !== '') {
            this.notificationService.showError(manipulatedErrorMessage);
          }
        }
      }
    );
  }

  convertListData(data) {
    if (data !== undefined && data !== null && data.length > 0) {
      // tslint:disable-next-line:prefer-for-of forin
      for (const dataItem in data) {
        data[dataItem].dateTimeRange = this.getDateTimeRange(data[dataItem].startDateStr, data[dataItem].endDateStr,
          data[dataItem].startTimeStr, data[dataItem].endTimeStr, data[dataItem].timezone);
        if (data[dataItem].createdBy !== undefined && data[dataItem].createdBy !== null) {
          data[dataItem].createdByName = this.getFullName(data[dataItem].createdBy);
        }
        if (data[dataItem].createdDate !== undefined && data[dataItem].createdDate !== null) {
          data[dataItem].createdDate = new Date(data[dataItem].createdDate);
        }
        if (data[dataItem].client !== undefined && data[dataItem].client !== null) {
          data[dataItem].clientList = [];
          for (const clientItem in data[dataItem].client) {
            data[dataItem].clientList.push(data[dataItem].client[clientItem]["name"]);
          }
        }
        if (data[dataItem].status !== undefined && data[dataItem].status !== null) {
          if (data[dataItem].status.id !== undefined && data[dataItem].status.id !== null) {
            if (data[dataItem].status.id === 1 || data[dataItem].status.id === 3) {
              data[dataItem].cancelAlert = false;
            } else {
              data[dataItem].cancelAlert = true;
            }
          }
        }
      }
    }
  }

  getFullName(userArray) {
    this.userFullName = "";
    if (userArray.firstName !== undefined && userArray.firstName !== null && userArray.firstName !== "") {
      this.userFullName = this.userFullName.concat((userArray.firstName).toString()) + " ";
    }
    if (userArray.lastName !== undefined && userArray.lastName !== null && userArray.lastName !== "") {
      this.userFullName = this.userFullName.concat((userArray.lastName).toString());
    }
    return this.userFullName;
  }

  getDateTimeRange(startDate, endDate, startTime, endTime, timezone) {
    this.dateTimeRange = "";
    if (startDate !== undefined && startDate !== null && startDate !== "") {
      const startTimeConversion = this.timeConvertFrom24To12(startTime);
      this.startDateTime = this.datePipe.transform(startDate, "MM/dd/yyyy") + ' ' + startTimeConversion;
      this.dateTimeRange = this.dateTimeRange.concat((this.startDateTime).toString()) + " " + timezone + " to ";
    }
    if (endDate !== undefined && endDate !== null && endDate !== "") {
      const endTimeConversion = this.timeConvertFrom24To12(endTime);
      this.endDateTime = this.datePipe.transform(endDate, "MM/dd/yyyy") + ' ' + endTimeConversion;
      this.dateTimeRange = this.dateTimeRange.concat((this.endDateTime).toString()) + " " + timezone;
    }
    return this.dateTimeRange;
  }

  timeConvertFrom24To12(time) {
    if (time !== undefined && time !== null) {
      time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
      if (time.length > 1) {
        time = time.slice(1);
        time[5] = +time[0] < 12 ? 'AM' : 'PM';
        time[0] = +time[0] % 12 || 12;
      }
      return time.join('').split(/(?=[A-Z]{2})/).join(" ");
    }
  }

  cancelAlert(alertId, alertData) {
    if (alertData["client"] !== undefined && alertData["client"] !== null) {
      alertData["client"] = alertData["client"]["id"];
    }
    if (alertData["createdBy"] !== undefined && alertData["createdBy"] !== null) {
      alertData["createdBy"] = alertData["createdBy"]["id"];
    }
    if (alertData["priority"] !== undefined && alertData["priority"] !== null) {
      alertData["priority"] = alertData["priority"]["id"];
    }
    alertData["status"] = 1;
    this.communicatorServiceObject.patchData(EndPointBaseUrl + API_CURRENT_VERSION +
      "/alerts/" + alertId, JSON.stringify(alertData)).subscribe(data => {
        if (data !== undefined && data !== null) {
          const successMessage = "Alert has been successfully cancelled.";
          this.notificationService.showSuccess(successMessage);
          this.updateAlertData(data);
        }
      }, error => {
        if (error.statusCode !== undefined && error.statusCode !== null && error.statusCode === 500) {
          this.notificationService.showError("Failed to process your request");
        } else {
          const manipulatedErrorMessage = this.errorMessageService.manipulateErrorMessageForDisplayingOnSnackBar(error);
          if (manipulatedErrorMessage !== undefined && manipulatedErrorMessage !== null && manipulatedErrorMessage !== '') {
            this.notificationService.showError(manipulatedErrorMessage);
          }
        }
      }
    );
  }

  updateAlertData(alertData) {
    if (alertData !== undefined && alertData !== null) {
      if (this.alertsList !== undefined && this.alertsList !== null && this.alertsList.length > 0) {
        for (let i = 0; i < this.alertsList.length; i++) {
          if (this.alertsList[i]['id'] === alertData['id']) {
            this.alertsList[i] = alertData;
            this.convertListData(this.alertsList);
            this.allAlertsList.next(this.alertsList);
          }
        }
      }
    }
  }

  getAllActiveAlertsList(status, clientId) {
    let queryParams = {};
    queryParams['status'] = status;
    if (clientId !== undefined && clientId !== null) {
      queryParams['clientId'] = clientId;
    }
    queryParams['ordering'] = "new_priority,start_time";
    queryParams = queryParams || {};
    let queryString = Object.keys(queryParams).map(key => encodeURIComponent(key) + "=" + encodeURIComponent(queryParams[key])).join("&");
    if (queryString !== "") {
      queryString = "?" + queryString;
    }
    this.communicatorServiceObject.getData(EndPointBaseUrl + API_CURRENT_VERSION +
      "/alerts" + queryString).subscribe(data => {
        if (data !== undefined && data !== null && data.results.length > 0) {
          this.activeAlertsList = data.results;
          this.allActiveAlertsList.next(this.activeAlertsList);
        }
      }, error => {
        if (error.statusCode !== undefined && error.statusCode !== null && error.statusCode === 500) {
          this.notificationService.showError("Failed to process your request");
        } else {
          const manipulatedErrorMessage = this.errorMessageService.manipulateErrorMessageForDisplayingOnSnackBar(error);
          if (manipulatedErrorMessage !== undefined && manipulatedErrorMessage !== null && manipulatedErrorMessage !== '') {
            this.notificationService.showError(manipulatedErrorMessage);
          }
        }
      }
    );
  }

  getSystemAlerts(status, systemAlert) {
    let queryParams = {};
    queryParams['status'] = status;
    queryParams['ordering'] = "new_priority,start_time";
    queryParams['systemalert'] = 1;
    queryParams = queryParams || {};
    let queryString = Object.keys(queryParams).map(key => encodeURIComponent(key) + "=" + encodeURIComponent(queryParams[key])).join("&");
    if (queryString !== "") {
      queryString = "?" + queryString;
    }
    this.communicatorServiceObject.getData(EndPointBaseUrl + API_CURRENT_VERSION +
      "/alerts" + queryString).subscribe(data => {
        if (data !== undefined && data !== null && data.results.length > 0) {
          this.activeSystemAlertsList = data.results;
          this.allActiveSystemAlertsList.next(this.activeSystemAlertsList);
        }
      }, error => {
        if (error.statusCode !== undefined && error.statusCode !== null && error.statusCode === 500) {
          this.notificationService.showError("Failed to process your request");
        } else {
          const manipulatedErrorMessage = this.errorMessageService.manipulateErrorMessageForDisplayingOnSnackBar(error);
          if (manipulatedErrorMessage !== undefined && manipulatedErrorMessage !== null && manipulatedErrorMessage !== '') {
            this.notificationService.showError(manipulatedErrorMessage);
          }
        }
      }
    );
  }
}
