import {Component, Injectable, OnInit, ViewChild} from "@angular/core";
import {
  DataManager,
  DataUtil,
  QueryList,
  Query,
  ParamOption,
  ODataAdaptor,
  Predicate,
  PvtOptions,
  QueryOptions,
  RemoteOptions,
  Requests,
  WebApiAdaptor, DataResult, DataOptions, CrudOptions
} from "@syncfusion/ej2-data";
import { Ajax } from "@syncfusion/ej2-base";
import {DatePipe} from "@angular/common";
import {CommonDateFormat} from "../settings/settings";

@Injectable({
  providedIn: "root"
})
export class SearchAdaptorService extends WebApiAdaptor  {
  public memberFullName: string;
  public providerFullName: string;
  public dateFormat: DatePipe;


  processResponse(data: DataResult, ds?: DataOptions, query?: Query, xhr?: XMLHttpRequest, request?: Ajax, changes?: CrudOptions): object {
    const members = data.results;
    for (const member in members) {
      members[member].name = this.getFullNameForMemberDropdown(members[member]);
      members[member].fullName = this.getFullNameForProviderList(members[member]);
      members[member].displayNameFormat = this.getExistingMemberDetailsOnAddChart(members[member]);
    }
    return members;
  }

  processQuery(dm: DataManager, query: Query, hierarchyFilters?: Object[]) {
    const queries: Requests = this.getQueryRequest(query);
    const singles: QueryList = Query.filterQueryLists(query.queries, ["onSelect", "onPage", "onSkip", "onTake", "onRange"]);
    const params: ParamOption[] = query.params;
    const url: string = dm.dataSource.url;
    let temp: QueryOptions;
    let skip: number;
    const take: number = null;
    const options: RemoteOptions = this.options;
    const request: Requests = { sorts: [], groups: [], filters: [], searches: [], aggregates: [] };
    // Filters
    for (let i = 0; i < queries.filters.length; i++) {
      request.filters.push((queries.filters[i].e.value as Predicate));
    }
    const req: { [key: string]: Object } = {};
    (this as any).getRequestQuery(options, query, singles, request, req);
    // Params
    DataUtil.callAdaptorFunction(this, "addParams", { dm, query, params, reqParams: req });
    // cleanup
    const keys: string[] = Object.keys(req);
    for (const prop of keys) {
      if (DataUtil.isNull(req[prop]) || req[prop] === "" || (req[prop] as Object[]).length === 0) {
        delete req[prop];
      }
    }
    if (!(options.skip in req && options.take in req) && take !== null) {
      req[options.skip] = DataUtil.callAdaptorFunction(this, "onSkip", skip, query);
      req[options.take] = DataUtil.callAdaptorFunction(this, "onTake", take, query);
    }
    const p: PvtOptions = this.pvt;
    this.pvt = {};
    if (this.options.requestType === "json") {
      return {
        data: JSON.stringify(req),
        url,
        pvtData: p,
        type: "POST",
        contentType: "application/json; charset=utf-8"
      };
    }
    temp = this.convertToQueryString(req, query, dm) as QueryOptions;
    temp = (dm.dataSource.url.indexOf("?") !== -1 ? "&" : "/") + temp as QueryOptions;
    // Here you can modify the request url as per your requirement
    return {
      type: "GET", url: (temp as string).length ? url.replace(/\/*$/, temp as string) : url, pvtData: p
    };
  }

  getFullNameForMemberDropdown(userArray) {
    this.memberFullName = "";
    if (userArray.firstName !== undefined && userArray.firstName !== null && userArray.firstName !== "") {
      this.memberFullName = this.memberFullName.concat((userArray.firstName).toString()) + " ";
    }
    if (userArray.lastName !== undefined && userArray.lastName !== null && userArray.lastName !== "") {
      this.memberFullName = this.memberFullName.concat((userArray.lastName).toString());
    }
    if (userArray.groupName !== undefined && userArray.groupName !== null && userArray.groupName !== "") {
      this.memberFullName = userArray.groupName;
    }
    if (userArray.facilityName !== undefined && userArray.facilityName !== null && userArray.facilityName !== "") {
      this.memberFullName = userArray.facilityName;
    }
    return this.memberFullName;
  }

  getExistingMemberDetailsOnAddChart(member) {
    if (member !== undefined && member !== null) {
      const newDate = new Date(member.dob).getDate();
      const getDateMonth = new Date(member.dob).getMonth() + 1;
      const getDateYear = new Date(member.dob).getFullYear();
      const memberDate = getDateMonth + "/" + newDate + "/" + getDateYear;
      member["displayNameFormat"] = member.memberId + " " + member.firstName + " "
        + member.lastName + " " + memberDate;
      return member["displayNameFormat"];
    }
  }

  /**
   * type
   * 1 -> individual
   * 2 -> group name
   * 3 -> facility name
   * @param providerListArray
   */
  getFullNameForProviderList(providerListArray) {
    this.providerFullName = "";
    if (providerListArray.type === 1) {
      if (providerListArray.lastName !== null && providerListArray.lastName !== undefined && providerListArray.lastName !== "") {
        this.providerFullName = this.providerFullName.concat((providerListArray.lastName).toString()) + "," + " ";
      }
      if (providerListArray.firstName !== null && providerListArray.firstName !== undefined && providerListArray.firstName !== "") {
        this.providerFullName = this.providerFullName.concat((providerListArray.firstName).toString()) + " " + "-" + " ";
      }
      if (providerListArray.npi !== null && providerListArray.npi !== undefined && providerListArray.npi !== "") {
        this.providerFullName = this.providerFullName.concat((providerListArray.npi).toString());
      }
    }
    if (providerListArray.type === 2) {
      if (providerListArray.groupName !== null && providerListArray.groupName !== undefined && providerListArray.groupName !== "") {
        this.providerFullName = providerListArray.groupName + " " + "-" + " ";
      }
      if (providerListArray.npi !== null && providerListArray.npi !== undefined && providerListArray.npi !== "") {
        this.providerFullName = this.providerFullName.concat((providerListArray.npi).toString());
      }
    }
    if (providerListArray.type === 3) {
      if (providerListArray.facilityName !== null && providerListArray.facilityName !== undefined && providerListArray.facilityName !== "") {
        this.providerFullName = providerListArray.facilityName + " " + "-" + " ";
      }
      if (providerListArray.npi !== null && providerListArray.npi !== undefined && providerListArray.npi !== "") {
        this.providerFullName = this.providerFullName.concat((providerListArray.npi).toString());
      }
    }
    return this.providerFullName;
  }
}
