import { Component,  Inject, OnInit, Optional } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
 import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
 import { DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'app-view-chart',
  templateUrl: './view-chart.component.html',
  styleUrls: ['./view-chart.component.scss']
})
export class ViewChartComponent implements OnInit {
  pdfUrl: any;

isIntegrated!:boolean
  constructor(public dialogRef: MatDialogRef<ViewChartComponent>,@Optional() @Inject(MAT_DIALOG_DATA) public data:any,private route: ActivatedRoute,private dom:DomSanitizer) { }

  ngOnInit(): void {
    // this.route.queryParams
    //   .subscribe(params => {
    //     if(params !== undefined && params !== null) {
    //       this.pdfUrl= this.dom.bypassSecurityTrustResourceUrl(params.pdfChart)
          
    //     }
    // });
   
    this.pdfUrl= this.dom.bypassSecurityTrustResourceUrl(this.data.chartDetails.pdf)
  }

  closeChart(): void {
    this.dialogRef.close(); 
  }

}
