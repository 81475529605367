import { Injectable } from "@angular/core";
import {Subject} from "rxjs";
import {CommunicatorService} from "../common/services/communicator.service";
import {NotificationService} from "../common/services/notification.service";
import {ErrorMessageService} from "../common/services/error-message.service";
import {DatePipe} from "@angular/common";
import {API_CURRENT_VERSION, EndPointBaseUrl} from "../common/settings/settings";
import {SelectClientService} from "../client/management/select-client/select-client.service";

@Injectable({
  providedIn: "root"
})
export class MailboxService {
  public mails = [];
  public mailsList = new Subject<any>();
  public mailsList$ = this.mailsList.asObservable();

  constructor(private communicatorServiceObject: CommunicatorService,
              public notificationService: NotificationService,
              public errorMessageService: ErrorMessageService,
              public selectClientService: SelectClientService) {

    this.selectClientService.selectedClientData$.subscribe(data => {
      if (data !== null && data !== undefined) {
        this.mails = [];
      }
    });
  }

  getMailNotifications(status, clientId) {
    let queryParams = {};
    queryParams['status'] = status;
    queryParams['clientId'] = clientId;
    queryParams = queryParams || {};
    let queryString = Object.keys(queryParams).map(key => encodeURIComponent(key) + "=" + encodeURIComponent(queryParams[key])).join("&");
    if (queryString !== "") {
      queryString = "?" + queryString;
    }
    this.communicatorServiceObject.getData(EndPointBaseUrl + API_CURRENT_VERSION +
      "/mail-notifications" + queryString).subscribe(data => {
        if (data !== null && data !== undefined) {
          this.mails = data.results;
          this.mailsList.next(this.mails);
        }
      }, error => {
        const manipulatedErrorMessage = this.errorMessageService.manipulateErrorMessageForDisplayingOnSnackBar(error);
        if (manipulatedErrorMessage !== undefined && manipulatedErrorMessage !== null && manipulatedErrorMessage !== '') {
          this.notificationService.showError(manipulatedErrorMessage);
        }
      }
    );
  }
}
