import { Injectable } from '@angular/core';
import {API_CURRENT_VERSION, EndPointBaseUrl} from "../settings/settings";
import {CommunicatorService} from "./communicator.service";
import {NotificationService} from "./notification.service";
import {ErrorMessageService} from "./error-message.service";
import {IClient} from "../../client/management/model/client";
import {Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class SystemClientsService {
  public clientList = Array<IClient>();
  public clientListOfLoggedInUser = new Subject<any>();
  public clientListOfLoggedInUser$ = this.clientListOfLoggedInUser.asObservable();

  constructor(private communicatorServiceObject: CommunicatorService,
              public notificationService: NotificationService,
              public errorMessageService: ErrorMessageService) { }

  getClientListOfLoggedInUser(force?: boolean) {
    let queryParams = {};
    queryParams['status'] = 1;
    queryParams = queryParams || {};
    let queryString = Object.keys(queryParams).map(key => encodeURIComponent(key) + "=" + encodeURIComponent(queryParams[key])).join("&");
    if (queryString !== "") {
      queryString = "?" + queryString;
    }
    if (force || (this.clientList !== null && this.clientList.length === 0)) {
      this.communicatorServiceObject.getData(EndPointBaseUrl + API_CURRENT_VERSION +
        "/clients" + queryString).subscribe(data => {
          if (data !== null && data !== undefined) {
            this.clientList = data.results;
            this.clientListOfLoggedInUser.next(this.clientList);
          }
        }, error => {
          const manipulatedErrorMessage = this.errorMessageService.manipulateErrorMessageForDisplayingOnSnackBar(error);
          if (manipulatedErrorMessage !== undefined && manipulatedErrorMessage !== null && manipulatedErrorMessage !== '') {
            this.notificationService.showError(manipulatedErrorMessage);
          }
        }
      );
    } else {
      this.clientListOfLoggedInUser.next(this.clientList);
      return this.clientList;
    }
  }

  getClientData(clientId) {
    for (const client of this.clientList) {
      if (client.id === clientId) {
        return client;
      }
    }
  }
}
