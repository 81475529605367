import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {
  constructor(private cookieService: CookieService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const contentTypes = ["application/json", "multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW"]
    const csrftoken = this.cookieService.get("csrftoken");
    const accessToken = sessionStorage.getItem("access_token");

    if (csrftoken !== null && csrftoken !== undefined && accessToken !== null && accessToken !== undefined) {
      const headers = new HttpHeaders({
        "Content-Type": (req.body instanceof FormData) ? contentTypes[1] : contentTypes[0],
        "Access-Control-Allow-Credentials": "true",
        Accept: "application/json",
        responseType: "arraybuffer",
        Authorization: "Bearer " + sessionStorage.getItem("access_token"),
        "X-CSRFToken": csrftoken
      });
      const cloneReq = req.clone({ headers });
      return next.handle(cloneReq);
    } else {
      const headers = new HttpHeaders({
        "Content-Type": (req.body instanceof FormData) ? contentTypes[1] : contentTypes[0],
        "Access-Control-Allow-Credentials": "true",
        Accept: "application/json",
        responseType: "arraybuffer",
        // Authorization: "Basic " + btoa("krakshitha:Admin@123"),
      });

      const cloneReq = req.clone({ headers });
      return next.handle(cloneReq);
    }
  }
}
