import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {ConfigurationService} from "./configuration.service";

@Injectable({
    providedIn: 'root'
})
export class NotificationService {

  constructor(
    public snackBar: MatSnackBar,
    private zone: NgZone,
    private applicationConfig: ConfigurationService) { }

  showSuccess(message: string): void {
    this.zone.run(() => {
      this.snackBar.open(message, 'X', {
        panelClass: ['success'],
        duration: this.applicationConfig.snackBarTiming
      });
    });
  }

  showError(message: string): void {
    this.zone.run(() => {
      this.snackBar.open(message, 'X', {
        panelClass: ['error'],
        duration: this.applicationConfig.snackBarTiming
      });
    });
  }
}
