import {Injectable} from "@angular/core";
import {BehaviorSubject, Subject} from "rxjs";
import {CommunicatorService} from "../../../common/services/communicator.service";
import {API_CURRENT_VERSION, EndPointBaseUrl} from "../../../common/settings/settings";
import {IProvider} from "../model/provider";
import {NotificationService} from "../../../common/services/notification.service";
import {ErrorMessageService} from "../../../common/services/error-message.service";
import {Router} from "@angular/router";
import {SelectClientService} from "../../../client/management/select-client/select-client.service";

@Injectable({
  providedIn: "root"
})
export class ListProvidersService {
  public providersList: IProvider[] = [];
  public allProvidersList = new Subject<IProvider[]>();
  public allProvidersList$ = this.allProvidersList.asObservable();
  public receivedProviderType: number;
  public userFullName: string;
  public fullAddress: string;

  constructor(private communicatorServiceObject: CommunicatorService,
              public notificationService: NotificationService,
              public errorMessageService: ErrorMessageService,
              private router: Router,
              public selectClientService: SelectClientService) {
  }

  getAllProvidersList(clientId, status, type, ordering) {
    this.receivedProviderType = type;
    let queryParams = {};
    if (clientId !== undefined && clientId !== null) {
      queryParams['clientId'] = clientId;
    }
    queryParams['status'] = status;
    if (type !== null) {
      queryParams['type'] = type;
    }
    queryParams['ordering'] = ordering;
    queryParams = queryParams || {};
    let queryString = Object.keys(queryParams).map(key => encodeURIComponent(key) + "=" + encodeURIComponent(queryParams[key])).join("&");
    if (queryString !== "") {
      queryString = "?" + queryString;
    }
    this.communicatorServiceObject.getData(EndPointBaseUrl + API_CURRENT_VERSION +
      "/providers" + queryString).subscribe(data => {
        this.providersList = [];
        if (data !== undefined && data !== null && data.results.length > 0) {
          this.providersList = data.results;
          this.convertListData(this.providersList);
        }
        this.allProvidersList.next(this.providersList);
      }, error => {
        if (error.statusCode === 403) {
          if (error.message["detail"] === "Authentication credentials were not provided.") {
            const manipulatedErrorMessage = this.errorMessageService.manipulateErrorMessageForDisplayingOnSnackBar(error);
            this.notificationService.showError(manipulatedErrorMessage);
            this.router.navigate(["/session/signin"]);
          }
        } else if (error.statusCode !== undefined && error.statusCode !== null && error.statusCode === 500) {
          this.notificationService.showError("Failed to process your request");
        } else {
          const manipulatedErrorMessage = this.errorMessageService.manipulateErrorMessageForDisplayingOnSnackBar(error);
          if (manipulatedErrorMessage !== undefined && manipulatedErrorMessage !== null && manipulatedErrorMessage !== '') {
            this.notificationService.showError(manipulatedErrorMessage);
          }
        }
      }
    );
  }

  convertListData(data) {
    if (data !== undefined && data !== null && data.length > 0) {
      // tslint:disable-next-line:prefer-for-of forin
      for (const dataItem in data) {
        data[dataItem].address = this.getFullAddress(data[dataItem].address1, data[dataItem].address2);
        if (data[dataItem].firstName !== undefined && data[dataItem].firstName !== null &&
          data[dataItem].lastName !== undefined && data[dataItem].lastName !== null) {
          data[dataItem].provider = this.getFullName(data[dataItem]);
        } else if (data[dataItem].groupName !== undefined && data[dataItem].groupName !== null) {
          data[dataItem].provider = data[dataItem].groupName;
        } else if (data[dataItem].facilityName !== undefined && data[dataItem].facilityName !== null) {
          data[dataItem].provider = data[dataItem].facilityName;
        }
      }
    }
    return data;
  }

  getFullName(userArray) {
    this.userFullName = "";
    if (userArray.firstName !== undefined && userArray.firstName !== null && userArray.firstName !== "") {
      this.userFullName = this.userFullName.concat((userArray.firstName).toString()) + " ";
    }
    if (userArray.lastName !== undefined && userArray.lastName !== null && userArray.lastName !== "") {
      this.userFullName = this.userFullName.concat((userArray.lastName).toString());
    }
    return this.userFullName;
  }

  getFullAddress(address1, address2) {
    this.fullAddress = "";
    if (address1 !== undefined && address1 !== null && address1 !== "") {
      this.fullAddress = this.fullAddress.concat((address1).toString()) + " "
    }
    if (address2 !== undefined && address2 !== null && address2 !== "") {
      this.fullAddress = this.fullAddress.concat((address2).toString());
    }
    return this.fullAddress;
  }
}
