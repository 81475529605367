import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef,MAT_DIALOG_DATA  } from '@angular/material/dialog';

@Component({
  selector: 'app-show-meat-list-dialog',
  templateUrl: './show-meat-list-dialog.component.html',
  styleUrls: ['./show-meat-list-dialog.component.scss']
})
export class ShowMeatListDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ShowMeatListDialogComponent>,@Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit(): void {}

  onCancel() {
    this.dialogRef.close();
  }

}
