<div style="overflow-y:hidden">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container" cdkDragHandle
        class="dialog-drag">
        <div fxLayout="row wrap" fxLayoutAlign="end">
            
        <!-- <h4>Meat Reference</h4> -->
            <span>
                <button mat-icon-button matTooltip="Click here to close the popup" (click)="onCancel()">
                    <mat-icon>close</mat-icon>
                </button>
            </span>
        </div>

    </div>
    <div class="content">
        <div class="control-section grid-display ecp-ejgrid-tight ecp-ejgrid-eclat-white ecp-ejgrid-background-navy">
            <ejs-grid #chartHistoryGrid [dataSource]="data.meatData" gridLines="Both" allowTextWrap='true' height="400">
                <e-columns>
                    <e-column field='extractedText' headerText='SENTENCE' width="90" type="text" textAlign="center">
                    </e-column>
                    <e-column field='pageNo' headerText='PAGE-NO' width="30" type="number" textAlign="center">
                    </e-column>

                </e-columns>
            </ejs-grid>
        </div>
    </div>
</div>