import {Component, Inject, OnDestroy, OnInit, Optional, ViewChild} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {UserService} from "../../user.service";
import {IUser} from "../model/user";
import {Subscription} from "rxjs";
import {ModifyUserProfileService} from "./modify-user-profile.service";
import {TimezoneService} from "../../../common/services/timezone.service";
import {DropDownListComponent} from "@syncfusion/ej2-angular-dropdowns";

@Component({
  selector: "app-modify-user-profile",
  templateUrl: "./modify-user-profile.component.html",
  styleUrls: ["./modify-user-profile.component.scss"]
})
export class ModifyUserProfileComponent implements OnInit, OnDestroy {
  public userProfileForm: FormGroup;
  public generalSortOrder = "Ascending";
  public timezoneFields: object = {text: "value", id: "key"};
  public clientId: number;
  public userProfileData: IUser;
  public userProfileSectionData$: Subscription;
  public userProfileDataList: [];
  public isEdit = false;
  public timezoneList = [];
  public firstName: string;
  public lastName: string;
  public phoneNumber: string;
  public updatedUserProfile$: Subscription;
  public timezone$: Subscription;
  @ViewChild("timeZoneDropdown")
  public timeZoneDropdown: DropDownListComponent;

  constructor(private dialogRef: MatDialogRef<ModifyUserProfileComponent>,
              private formBuilder: FormBuilder,
              @Optional() @Inject(MAT_DIALOG_DATA) public data,
              public userService: UserService,
              public timezoneService: TimezoneService,
              public modifyUserProfileService: ModifyUserProfileService) { }

  ngOnInit() {
    this.userProfileForm = this.buildUserProfileForm();
    this.userProfileForm.get("username").disable();
    this.userProfileForm.get("email").disable();
    this.userProfileForm.get("firstName").disable();
    this.userProfileForm.get("phoneNumber").disable();
    this.userProfileForm.get("lastName").disable();
    this.userProfileForm.get("timezone").disable();
    this.userProfileData = new IUser();
    this.timezone$ = this.timezoneService.timezoneList$.subscribe(data => {
      if (data !== undefined && data !== null && data.length > 0) {
        this.timezoneList = data;
      } else {
        this.timezoneList = [];
        this.timeZoneDropdown.noRecordsTemplate = "No Records To Display";
      }
    });
    this.timezoneService.getTimezoneList();
    this.userProfileSectionData$ = this.userService.loggedInUserPermissions$.subscribe(data => {
      if (data !== undefined && data !== null) {
        this.userProfileDataList = data;
        this.userProfileForm.patchValue(this.userProfileDataList);
      }
    });
    this.userService.getLoggedInPermissions();
  }

  buildUserProfileForm(): FormGroup {
    return this.formBuilder.group({
      firstName: [null, [Validators.required]],
      lastName: [null, [Validators.required]],
      username: null,
      phoneNumber: null,
      timezone: [null, [Validators.required]],
      email: null
    });
  }

  onEditProfile(isEdit){
    this.isEdit = true;
    if (!isEdit){
      this.validateAllFormFields(this.userProfileForm);
      this.userProfileForm.get("firstName").enable();
      this.userProfileForm.get("phoneNumber").enable();
      this.userProfileForm.get("lastName").enable();
      this.userProfileForm.get("timezone").enable();
    }
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  updateProfile(isEdit) {
    if (isEdit === true) {
      if (this.userProfileForm.valid) {
        this.modifyUserProfileService.updateProfile(this.userProfileForm.value, this.userProfileDataList["id"]);
        this.updatedUserProfile$ = this.modifyUserProfileService.updateUserProfileData$.subscribe(data => {
          if (data !== undefined && data !== null) {
            this.userService.updatedUserProfile(data);
            this.dialogRef.close();
          }
        });
      }
    }
  }

  public firstNameInputHandler(event): void {
    if (event && event.value !== undefined && event.value !== null && event.value !== "") {
      const firstname = event.value;
      this.firstName = firstname.trim();
    }
  }

  public lastNameInputHandler(event): void {
    if (event && event.value !== undefined && event.value !== null && event.value !== "") {
      const lastname = event.value;
      this.lastName = lastname.trim();
    }
  }

  public phoneNumberInputHandler(event): void {
    if (event && event.value !== undefined && event.value !== null && event.value !== "") {
      const phoneNumber = event.value;
      this.phoneNumber = phoneNumber.trim();
    }
  }

  onCancel() {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    if (this.userProfileSectionData$) {
      this.userProfileSectionData$.unsubscribe();
    }
    if (this.updatedUserProfile$) {
      this.updatedUserProfile$.unsubscribe();
    }
    if (this.timezone$) {
      this.timezone$.unsubscribe();
    }
  }

  searchTimezone(event) {
    if (event !== undefined && event !== null) {
      if (event.text !== undefined && event.text !== null && event.text !== "") {
        this.timeZoneDropdown.noRecordsTemplate = "No Records To Display";
      }
    }
  }
}
