import { Injectable } from "@angular/core";
import { Router, CanActivate } from "@angular/router";
import { AuthService } from "./auth.service";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { CookieService } from "ngx-cookie";

@Injectable({
  providedIn: "root"
})
export class AuthGaurdService implements CanActivate {

  constructor(private http: HttpClient, private cookieService: CookieService,
    public auth: AuthService, public router: Router) { }


  /**
   * Route activation check - ONLY allow if user is authenticated
   * Otherwise redirect to sign-in
   * protect all routes which are ONLY suppose to be accessible if user is login
   * with it
   */
  canActivate(): Observable<boolean> {
    if (!this.auth.authenticated) {
      const csrftoken = this.cookieService.get("csrftoken");
      const httpOptions = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          "Access-Control-Allow-Credentials": "true",
          Accept: "application/json",
          "X-CSRFToken": csrftoken
        }),
      };
      return this.http.get("", httpOptions)
        .pipe(map(e => {
          return !!e;
        }),
          catchError((err) => {
            this.router.navigate(["/session/signin"]);
            return of(false);
          })
        );
    } else {
      return of(true);
    }
  }
}
