import { AdminLayoutComponent, AuthLayoutComponent } from "./core";
import {AuthGaurdService as AuthGaurd} from "./common/services/auth-gaurd.service";

import { Routes } from "@angular/router";
import { ViewChartComponent } from "./core/header/view-chart/view-chart.component";

export const AppRoutes: Routes = [
  {
    path: "",
    component: AdminLayoutComponent,
    children: [
      {
        path: "",
        canActivate: [AuthGaurd],
        loadChildren: () =>
          import("./dashboard/dashboard.module").then(m => m.DashboardModule)
      }, {
        path: "users",
        canActivate: [AuthGaurd],
        loadChildren: () =>
          import("./user/user.module").then(m => m.UserModule)
      },
      {
        path: "coding",
        canActivate: [AuthGaurd],
        loadChildren: () =>
          import("./coding/coding.module").then(m => m.CodingModule)
      },
      {
        path: "client",
        canActivate: [AuthGaurd],
        loadChildren: () =>
          import("./client/client.module").then(m => m.ClientModule)
      },
      {
        path: "",
        canActivate: [AuthGaurd],
        loadChildren: () =>
          import("./queue/queue.module").then(m => m.QueueModule)
      },
      {
        path: "",
        canActivate: [AuthGaurd],
        loadChildren: () =>
          import("./education/education.module").then(m => m.EducationModule)
      },
      {
        path: "project",
        canActivate: [AuthGaurd],
        loadChildren: () =>
          import("./project/project.module").then(m => m.ProjectModule)
      },
      {
        path: "reports",
        canActivate: [AuthGaurd],
        loadChildren: () =>
          import("./reports/reports.module").then(m => m.ReportsModule)
      },
      {
        path: "provider",
        canActivate: [AuthGaurd],
        loadChildren: () =>
          import("./provider/provider.module").then(m => m.ProviderModule)
      },
      {
        path: "providers",
        canActivate: [AuthGaurd],
        loadChildren: () =>
          import("./provider/providers/providers.module").then(m => m.ProvidersModule)
      },
      {
        path: "concurrent",
        canActivate: [AuthGaurd],
        loadChildren: () =>
          import("./concurrent/concurrent.module").then(m => m.ConcurrentModule)
      },
      {
        path: "guidelines",
        canActivate: [AuthGaurd],
        loadChildren: () =>
          import("./guidelines/guidelines.module").then(m => m.GuidelinesModule)
      },
      {
        path: "education",
        canActivate: [AuthGaurd],
        loadChildren: () =>
          import("./education/education.module").then(m => m.EducationModule)
      },
      {
        path: "alerts",
        canActivate: [AuthGaurd],
        loadChildren: () =>
          import("./alerts/alerts.module").then(m => m.AlertsModule)
      },
      {
        path: "coding-profile",
        canActivate: [AuthGaurd],
        loadChildren: () =>
          import("./coding-profile/coding-profile.module").then(m => m.CodingProfileModule)
      },
      {
        path: "chart-repository",
        canActivate: [AuthGaurd],
        loadChildren: () =>
          import("./chart-repository/chart-repository.module").then(m => m.ChartRepositoryModule)
      },
      {
        path: "query-maps",
        canActivate: [AuthGaurd],
        loadChildren: () =>
          import("./query-maps/query-maps.module").then(m => m.QueryMapsModule)
      },
    ]
  },
  {
    path: "",
    component: AuthLayoutComponent,
    children: [
      {
        path: "session",
        loadChildren: () =>
          import("./session/session.module").then(m => m.SessionModule)
      }
    ]
  },
  {
    path:"viewRecord",
    component:ViewChartComponent
  }
];
