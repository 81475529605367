<mat-toolbar class="main-header" color="white">
  <div class="brand">
    <span i18n class="logo">
      <img src="/assets/brand/eclat-platform-logo.png">
    </span>
  </div>
  <div fxFlex="2"></div>
  <div fxLayout="row" fxLayoutGap="16px">
    <div class="chart-info-text" *ngIf="chartData && chartData.chartId">Chart ID: {{chartData?.chartId}}</div>
    <div class="chart-info-text" *ngIf="chartData">
      <!-- <button [disabled]="chartData && chartData.project.name === 'Concurrent project Demo-1'" ejs-button
              cssClass="e-small" class="pdf-button"
              (click)="openChartPDF(chartData)">PDF ID: {{chartData?.pdfId}}</button> -->
      <!-- <button *ngIf="chartData && !(selectedProjectType === concurrentProject || selectedProjectType === concurrentCptProject)" ejs-button
              cssClass="e-small" class="pdf-button"
              (click)="openChartPDF(chartData)">View PDF</button> -->

      <!-- <button *ngIf="chartData && (selectedProjectType === concurrentProject || selectedProjectType === concurrentCptProject) && chaExists"  ejs-button -->
      <button *ngIf="chartData && chaExists" ejs-button cssClass="e-small" style="text-transform: none;"
        class="pdf-button" (click)="OpenviewRecord(chartData)">View CHA </button>
    </div>
    <div class="chart-info-text" *ngIf="chartData && chartData.pdfPageCount && chartData.pdfPageCount !== null">Page
      Count:
      <span>{{chartData?.pdfPageCount}}</span>
    </div>
  </div>
  <div fxFlex></div>

  <label data-testid="TopNavEmail" style="font-weight:bold !important;font-size:14px !important;" mat-icon-button
    *ngIf="selectedClientName !== null" [ngClass]="{'hide': viewMode}">Client:&nbsp;{{selectedClientName}}&nbsp;</label>
  <button data-testid="TopNavEmail" mat-icon-button *ngIf="hideIcons === false" [ngClass]="{'hide': viewMode}"
    [matMenuTriggerFor]="mailMenu">
    <mat-icon [matBadge]="mailsList?.length" [matBadgeHidden]="mailsList?.length === 0"
      matBadgeSize="small">email</mat-icon>
  </button>
  <mat-menu #mailMenu="matMenu" xPosition="before">
    <mat-list class="alert-list" class="mail-list">
      <mat-list-item *ngFor="let mail of mailsList">
        <mat-icon matListIcon>mail</mat-icon>
        <p matLine>
          <span fxLayout="row wrap">{{mail.message}}</span>
        </p>
      </mat-list-item>
    </mat-list>
  </mat-menu>

  <button data-testid="TopNavErrOutline" mat-icon-button [matMenuTriggerFor]="alertsMenu"
    [ngClass]="{'hide': viewMode}">
    <mat-icon [style]="{color: alertIconColor}">info</mat-icon>
  </button>
  <mat-menu #alertsMenu="matMenu" xPosition="before">
    <mat-list class="alert-list">
      <mat-list-item *ngFor="let alert of alertsList" class="alerts-list">
        <mat-icon matListIcon [style]="{color: alert.priority.name}">info</mat-icon>
        <h3 matLine><b>{{alert.title}}</b></h3>
        <p matLine>
          <span fxLayout="row wrap">{{alert.message}}</span>
        </p>
      </mat-list-item>
    </mat-list>
  </mat-menu>

  <button data-testid="TopNavHelpOutline" [ngClass]="{'hide': viewMode}" mat-icon-button
    [matMenuTriggerFor]="helpMenuItems" [disabled]="hideIcons === true">
    <mat-icon>help_outline</mat-icon>
  </button>
  <mat-menu #helpMenuItems="matMenu" xPosition="before">
    <div>
      <div fxLayout="row" fxLayoutAlign="space-around">
        <table>
          <tr>
            <th colspan=2 class="help-table-caption">Help Desk</th>
          </tr>
          <tr>
            <td> Email Address </td>
            <td><a href="mailto:{{clientData?.accountManagerEmail}}"
                style="color: blue">{{clientData?.accountManagerEmail}}</a></td>
          </tr>
          <tr>
            <td> Phone Number </td>
            <td>{{clientData?.accountManagerPhoneNumber}}</td>
          </tr>
        </table>
      </div>
    </div>
  </mat-menu>

  <button data-testid="TopNavAppMenu" mat-icon-button [matMenuTriggerFor]="appMenuItems"
    *ngIf="hideIcons === false && viewMode === false && selectedClientName != null">
    <mat-icon>apps</mat-icon>
  </button>
  <mat-menu #appMenuItems="matMenu">
    <div class="icon-menu">
      <div fxLayout="row" fxLayoutAlign="space-around">
        <div fxLayout="column">
          <div fxLayout="column" class="caption column-1">
            <div class="icon-menu-item">
              <button data-testid="ExclusionAppMenu" mat-icon-button [disabled]="isUserExclusionDisabled"
                (click)="routeToPersonalExclusion()">
                <mat-icon [ngClass]="{'disable-button': isUserExclusionDisabled}">login</mat-icon>
              </button>
            </div>
            <div class="clickable-link">
              <a (click)="routeToPersonalExclusion()" [ngClass]="{'disable-button': isUserExclusionDisabled}">User
                Exclusion</a>
            </div>
          </div>
          <div fxLayout="column" class="caption">
            <div class="icon-menu-item">
              <button data-testid="GuidelinesAppMenu" mat-icon-button (click)="openCodingGuidelinesPopup()">
                <mat-icon>speaker_notes</mat-icon>
              </button>
            </div>
            <div class="clickable-link">
              <a (click)="openCodingGuidelinesPopup()">Guidelines</a>
            </div>
          </div>
        </div>

        <div fxLayout="column">
          <div fxLayout="column" class="caption column-1">
            <div class="icon-menu-item">
              <button data-testid="LockedChartsAppMenu" mat-icon-button [disabled]="isLockChartDisabled"
                (click)="routeToLockedCharts()">
                <mat-icon [ngClass]="{'disable-button': isLockChartDisabled}">lock</mat-icon>
              </button>
            </div>
            <div class="clickable-link">
              <a (click)="routeToLockedCharts()" [ngClass]="{'disable-button': isLockChartDisabled}">Lock Chart</a>
            </div>
          </div>
          <div fxLayout="column" class="caption">
            <div class="icon-menu-item">
              <button data-testid="EducationAppMenu" mat-icon-button (click)="openEducationPopup()">
                <mat-icon>library_books</mat-icon>
              </button>
            </div>
            <div (click)="openEducationPopup()" class="clickable-link">Education</div>
          </div>
        </div>

        <div fxLayout="column">
          <div fxLayout="column" class="caption column-1">
            <div class="icon-menu-item">
              <button mat-icon-button [disabled]="isUserProductivityDisabled" (click)="routeToUserProductivityReport()">
                <mat-icon [ngClass]="{'disable-button': isUserProductivityDisabled}">hourglass_full</mat-icon>
              </button>
            </div>
            <div class="clickable-link">
              <a (click)="routeToUserProductivityReport()"
                [ngClass]="{'disable-button': isUserProductivityDisabled}">Productivity</a>
            </div>
          </div>
          <div fxLayout="column" class="caption">
            <div class="icon-menu-item">
              <button data-testid="FeedbackAppMenu" [disabled]="isUserFeedbackDisabled" mat-icon-button
                (click)="routeToFeedbackQueue()">
                <mat-icon [ngClass]="{'disable-button': isUserFeedbackDisabled}">feedback</mat-icon>
              </button>
              <div class="clickable-link">
                <a (click)="routeToFeedbackQueue()" [ngClass]="{'disable-button': isUserFeedbackDisabled}">Feedback</a>
              </div>
            </div>
          </div>
        </div>

        <div fxLayout="column">
          <div fxLayout="column" class="caption column-1">
            <div class="icon-menu-item">
              <button data-testid="LockedChartsAppMenu" mat-icon-button [disabled]="isAccuracyDisabled"
                (click)="routeToAccuracy()">
                <mat-icon [ngClass]="{'disable-button': isAccuracyDisabled}">percent</mat-icon>
              </button>
            </div>
            <div class="clickable-link">
              <a (click)="routeToAccuracy()" [ngClass]="{'disable-button': isAccuracyDisabled}">Accuracy</a>
            </div>
          </div>
        </div>

        <!--        <div fxLayout="column">-->
        <!--          <div fxLayout="column" class="caption column-1">-->
        <!--            <div class="icon-menu-item">-->
        <!--              <button mat-icon-button routerLink="/concurrent/queue">-->
        <!--                <mat-icon>search</mat-icon>-->
        <!--              </button>-->
        <!--            </div>-->
        <!--            <div class="clickable-link" routerLink="/concurrent/queue">Provider Query</div>-->
        <!--          </div>-->
        <!--        </div>-->

      </div>
    </div>
  </mat-menu>

  <div *ngIf="hideIcons === false && viewMode === false && selectedClientName != null">
    <ejs-menu #menu [items]='menuItems' (beforeOpen)='beforeOpen($event)' (created)='created()' class="menu"
      (select)="getSelectedMenu($event)"></ejs-menu>

  </div>

  <button [ngClass]="{'hide': viewMode}" data-testid="TopNavUser" [matMenuTriggerFor]="user" mat-icon-button
    class="ml-xs">
    <mat-icon>person</mat-icon>
  </button>
  <mat-menu #user="matMenu" x-position="before" [ngClass]="{'hide': viewMode}">
    <button mat-menu-item class="default-cursor-menu">
      <span i18n>{{firstName}} {{lastName}}</span>
    </button>
    <button mat-menu-item class="default-cursor-menu">
      <span i18n>{{username}}</span>
    </button>
    <button mat-menu-item (click)="openManageProfileSectionDialog($event)">
      <span i18n>Manage Your Profile</span>
    </button>
    <button mat-menu-item (click)="switchClient($event)">
      <mat-icon>corporate_fare</mat-icon>
      <span i18n>Switch Client</span>
    </button>
    <button mat-menu-item (click)="signOut()">
      <mat-icon>exit_to_app</mat-icon>
      <span i18n>Log Out</span>
    </button>
  </mat-menu>
</mat-toolbar>